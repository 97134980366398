import react, { useState } from 'react';
import SideNav from '../../components/side_nav';
import { Input, Select, Table, TableProps, Button } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';

interface DataType {
  key: string;
  numb: string;
  default: string;
  edit: string;
}

const AnswerSettingPage = (props: any) => {
  const [editText, setEditText] = useState(['지지한다', '지지하지 않는다', '선택하지 않음', '기타반응', '통화중 끊음', '부재중', '통화중', '결번', '수신거부']);
  const dataSource: DataType[] = [
  {
    key: '1',
    numb: '1',
    default: '우리후보',
    edit: editText[0],
  },
  {
    key: '2',
    numb: '2',
    default: '타후보',
    edit: editText[1],
  },
  {
    key: '3',
    numb: '3',
    default: '지지없음',
    edit: editText[2],
  },
  {
    key: '4',
    numb: '4',
    default: '응답안함',
    edit: editText[3],
  },
  {
    key: '5',
    numb: '5',
    default: '기타응답',
    edit: editText[4],
  },
  {
    key: '6',
    numb: '6',
    default: '부재중',
    edit: editText[5],
  },
  {
    key: '7',
    numb: '7',
    default: '통화중',
    edit: editText[6],
  },
  {
    key: '8',
    numb: '8',
    default: '결번',
    edit: editText[7],
  },
  {
    key: '9',
    numb: '9',
    default: '수신거부',
    edit: editText[8],
  },
];

const columns: TableProps<DataType>['columns'] = [
  {
    title: '번호',
    dataIndex: 'numb',
    key: 'numb',
    width: 100,
    align: 'center',
  },
  {
    title: '기본문구',
    dataIndex: 'default',
    key: 'default',
  },
  {
    title: '수정문구',
    dataIndex: 'edit',
    key: 'edit',
    render: (text, record, index) => index < 6 ? <Input value={editText[index]} placeholder={'변경할 문구 입력...'} /> : text
  },
];

const {onLogout} = props;

const [folders, setFolders] = useState<any>([{
  title: '파일1.xlsx',
  _id: 0,
},{
  title: '파일2.xlsx',
  _id: 1,
},{
  title: '파일3.xlsx',
  _id: 2,
},{
  title: '파일4.xlsx',
  _id: 3,
}]);
const [selectedFolder, setSelectedFolder] = useState('파일1.xlsx');

const selectChange = (value: string) => {
  setSelectedFolder(value);
};

  return (
    <div className='split-frame'>
      <SideNav selected='5' onLogout={onLogout} />
      <div className='split-frame-right-wrap'>
        <div className='split-frame-right scroll-area'>
          <div className='split-frame-title'>
            상담 답변 관리
          </div>
          <div className='split-frame-title-divide' />
          <div className='body-option-row'>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>파일명</div>
              <div className='body-option-item'>
                <Select
                  style={{ width: 240 }}
                  onChange={selectChange}
                  value={selectedFolder}
                  options={
                    folders.map((folder: any) => {
                      return {
                        label: folder.title,
                        value: folder._id,
                      };
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className='divide' />
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
          <Button>답변 저장</Button>
          </div>
          <div className='gap8' />
          <Table dataSource={dataSource} columns={columns} bordered pagination={false} />
        </div>
      </div>
    </div>
  );
};

export default AnswerSettingPage;