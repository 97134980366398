import { useEffect, useRef, useState } from 'react';
import { jwtDecode } from "jwt-decode";
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from './config/routes';
import API from './config/api';
import './styles/common.scss';
import VoterRegisterPage from './pages/admin/voter_register';
import AssignPage from './pages/admin/assign';
import CandInfoPage from './pages/admin/cand_info';
import CandVoicePage from './pages/admin/cand_voice';
import AiVoicePage from './pages/admin/ai_voice';
import ResearchListPage from './pages/admin/research_list';
import TaskStatistic from './pages/admin/task_statistic';
import AccountPage from './pages/admin/account';
import LoginPage from './pages/login';
import VoterListPage from './pages/surveyor/voter_list';
import CandidateVoterListPage from './pages/candidate/voter_list';
import DownloadPage from './pages/download/download';
import AnswerSettingPage from './pages/admin/answer_setting';
import ChargeStatisticPage from './pages/admin/charge_statistic';
import ResultStatisticPage from './pages/admin/result_statistic';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState('');

  useEffect(() => {
    req();
  }, []);

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     ua.current = new JsSIP.UA(getSipConfigs('1001@aiesc.touchcall.io', 'AIEsC1001SwPS'));

  //     ua.current.start();
  //   } else if (ua.current !== null) {
  //     ua.current = null;
  //   }
  // }, [isLoggedIn]);

  // const call = (number: string) => {
  //   const eventHandlers = {
  //     'progress': function() {
  //       console.log('call is in progress');
  //     },
  //     'failed': function(e: any) {
  //       console.log(e);
  //       console.log('call failed with cause: ');
  //     },
  //     'ended': function(e: any) {
  //       console.log(e);
  //       console.log('call ended with cause: ');
  //     },
  //     'confirmed': function(e: any) {
  //       console.log('call confirmed');
  //     }
  //   };

  //   const options = {
  //     'eventHandlers'    : eventHandlers,
  //     'mediaConstraints' : { 'audio': true, 'video': false },
  //   };

  //   ua.current.call(number, options);
  // }

  const req = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      return;
    }

    const res = await fetch(API.validateJWT, {
      method: 'GET',
      'headers': {
        'access-token': accessToken ?? '',
      },
    });

    const isSuccess = res.status === 200;

    if (!isSuccess) {
      return;
    }
  
    const tokenPayload = jwtDecode(accessToken);
    const {user_type} = tokenPayload as any;
  
    setUserType(user_type);
    setIsLoggedIn(true);

    req2();
  };

  const [phoneNumber, setPhoneNumber] = useState('');
  const req2 = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      return;
    }

    const res = await fetch(API.get_camp_info, {
      method: 'GET',
      'headers': {
        'access-token': accessToken ?? '',
      },
    });

    const isSuccess = res.status === 200;

    if (!isSuccess) {
      return;
    }

    const json = await res.json();
  
    setPhoneNumber(json.data.camp.camp_share_phone_number);
  };

  const login = async (email: any, pwd: any, successCb: () => void, failureCb: () => void) => {
    try {
      const res = await fetch(API.issueJWT, {
        'method': 'POST',
        'headers': {
          'Content-Type': 'application/json',
          'access-token': localStorage.getItem('access_token') ?? '',
        },
        'body': JSON.stringify({
          id: email,
          pwd,
        }),
      });

      if (res.status !== 200) {
        logout(failureCb);
        return;
      }

      const json = await res.json();
      console.log(json);
      if (!json.success) {
        alert(json.error);
        failureCb();
        return;
      }

      const accessToken = json.data.accessToken;
      localStorage.setItem('access_token', accessToken);

      console.log(accessToken.split('.')[1]);

      const tokenPayload = jwtDecode(accessToken);
      const {user_type} = tokenPayload as any;
      setUserType(user_type);
      setIsLoggedIn(true);
      successCb();
    } catch (err) {
      console.log(err);
      failureCb();
    }
  };

  const logout = async (successCb: () => void) => {
    await fetch(API.revokeJWT, {
      'method': 'DELETE',
      'headers': {
        'access-token': localStorage.getItem('access_token') ?? '',
      },
    })

    setIsLoggedIn(false);
    localStorage.removeItem('access_token');

    successCb();
  };

  if (!isLoggedIn) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path={routes.default} element={<LoginPage onLogin={login} />} />
          <Route path={routes.download} element={<DownloadPage />} />
          <Route path={"*"} element={<LoginPage onLogin={login} />} />
        </Routes>
      </BrowserRouter>
    )
  }

  if (isLoggedIn && userType === 'candidate') {
    return (
      <BrowserRouter>
        <Routes>
          <Route path={routes.default} element={<CandidateVoterListPage onLogout={logout} />} />
          <Route path={routes.cdVoterList} element={<CandidateVoterListPage onLogout={logout} />} />
          <Route path={routes.download} element={<DownloadPage />} />
          <Route path={"*"} element={<CandidateVoterListPage onLogout={logout} />} />
        </Routes>
      </BrowserRouter>
    )
  }

  if (isLoggedIn && userType === 'surveyor') {
    return (
      <BrowserRouter>
        <Routes>
          <Route path={routes.default} element={<VoterListPage onLogout={logout} />} />
          <Route path={routes.voterList} element={<VoterListPage onLogout={logout} phoneNumber={phoneNumber} />} />
          <Route path={routes.download} element={<DownloadPage />} />
          <Route path={"*"} element={<VoterListPage onLogout={logout} />} />
        </Routes>
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.default} element={<VoterRegisterPage onLogout={logout} />} />
        <Route path={routes.voterRegister} element={<VoterRegisterPage onLogout={logout} />} />
        <Route path={routes.assign} element={<AssignPage onLogout={logout} />} />
        <Route path={routes.candInfo} element={<CandInfoPage onLogout={logout} />} />
        <Route path={routes.candVoice} element={<CandVoicePage onLogout={logout} />} />
        <Route path={routes.aiVoice} element={<AiVoicePage onLogout={logout} />} />
        <Route path={routes.researchList} element={<ResearchListPage onLogout={logout} />} />
        <Route path={routes.taskStatistic} element={<TaskStatistic onLogout={logout} />} />
        <Route path={routes.account} element={<AccountPage onLogout={logout} />} />
        <Route path={routes.answerSetting} element={<AnswerSettingPage onLogout={logout} />} />
        <Route path={routes.chargeStatistic} element={<ChargeStatisticPage onLogout={logout} />} />
        <Route path={routes.resultStatistic} element={<ResultStatisticPage onLogout={logout} />} />
        <Route path={routes.download} element={<DownloadPage />} />
        <Route path={"*"} element={<VoterRegisterPage onLogout={logout} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
