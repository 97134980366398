import { AppstoreOutlined, AudioOutlined, BarChartOutlined, DeploymentUnitOutlined, DollarOutlined, FileSearchOutlined, FileTextOutlined, KeyOutlined, LeftSquareOutlined, LogoutOutlined, MailOutlined, MoreOutlined, OrderedListOutlined, PieChartOutlined, SettingOutlined, StockOutlined, UserSwitchOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Menu, MenuProps, Modal } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from '../config/routes';
import MenuItem from 'antd/es/menu/MenuItem';

interface Props{
  selected: string;
  onLogout: (a: any, b: any) => void;
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const adminItems: MenuProps['items'] = [
  getItem('유권자 등록', '0', <UsergroupAddOutlined />),
  getItem('상담원별 유권자 할당', '1', <UserSwitchOutlined />),

  getItem('상담 관리', 'sub1', <AppstoreOutlined />, [
    getItem('후보자 정보 관리', '2', <FileTextOutlined />),
    // getItem('후보자 음성 관리', '3', <AudioOutlined />),
    getItem('AI 음성 관리', '4', <DeploymentUnitOutlined />),
    getItem('상담 답변 관리', '5', <OrderedListOutlined />),
  ]),

  getItem('통계', 'sub2', <PieChartOutlined />, [
    getItem('상담 이력', '6', <FileSearchOutlined />),
    getItem('상담원별 작업 현황', '7', <BarChartOutlined />),
    getItem('결과 분석', '8', <StockOutlined />),
    getItem('요금 현황', '9', <DollarOutlined />),
  ]),

  getItem('설정', 'sub3', <SettingOutlined />, [
    getItem('ID 관리', '10', <KeyOutlined />),
    // getItem('기타', '8', <MoreOutlined />),
  ]),
];

const surveyorItem: MenuProps['items'] = [
  getItem('유권자 리스트', '11', <UsergroupAddOutlined />),
];

const candidateItem: MenuProps['items'] = [
  getItem('유권자 리스트', '12', <UsergroupAddOutlined />),
];

const SideNav:React.FC<Props> = (props) => {
  const {onLogout} = props;
  const navigate = useNavigate();
  const [logoutModal, setLogoutModal] = useState(false);
  const logoutSubmit = () => {
    onLogout(() => {}, () => setLogoutModal(false));
  };
  const logoutCancel = () => {
    setLogoutModal(false);
  };

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key === '0') {
      navigate(routes.voterRegister);
    } else if (e.key === '1') {
      navigate(routes.assign);
    } else if (e.key === '2') {
      navigate(routes.candInfo)
    } else if (e.key === '3') {
      navigate(routes.candVoice)
    } else if (e.key === '4') {
      navigate(routes.aiVoice)
    } else if (e.key === '5') {
      navigate(routes.answerSetting)
    } else if (e.key === '6') {
      navigate(routes.researchList)
    } else if (e.key === '7') {
      navigate(routes.taskStatistic)
    } else if (e.key === '8') {
      navigate(routes.resultStatistic)
    } else if (e.key === '9') {
      navigate(routes.chargeStatistic)
    } else if (e.key === '10') {
      navigate(routes.account)
    } else if (e.key === '11') {
      navigate(routes.voterList)
    } else if (e.key === '12') {
      navigate(routes.cdVoterList)
    }
  };

  return (
    <div className='side-nav'>
      <Modal
        title="로그아웃"
        open={logoutModal}
        onOk={logoutSubmit}
        onCancel={logoutCancel}
        footer={[
          <Button key="back" onClick={logoutCancel}>
            취소
          </Button>,
          <Button key="submit" danger onClick={logoutSubmit}>
            로그아웃
          </Button>
        ]}
      >
        로그아웃 하시겠습니까?
      </Modal>
      <div>
        <div className='side-nav-logo-wrap'>
          AI-ESC
        </div>
        <Menu
          onClick={onClick}
          style={{ width: 256, border: 'none' }}
          defaultSelectedKeys={[props.selected]}
          defaultOpenKeys={['sub1', 'sub2', 'sub3', 'sub4']}
          mode="inline"
          items={parseInt(props.selected) < 11 ? adminItems : (props.selected === '11') ? surveyorItem : candidateItem}
        />
      </div>
      <div className='side-nav-log-out' onClick={() => setLogoutModal(true)}>
        <LogoutOutlined />
        로그아웃
      </div>
    </div>
  );
};

export default SideNav;