import React, { useEffect, useState } from 'react';
import SideNav from '../../components/side_nav';
import { Button, DatePicker, Select, Table, TableProps } from 'antd';
import api from '../../config/api';
import { SearchOutlined } from '@ant-design/icons';

interface DataType {
  key: number,
  numb: string,
  name: string,
  serviceName: string,
  totalAmount: number,
  totalTime: string,
  marketingAmount: number,
  marketingTime: string,
  surveyAmount: number,
  surveyTime: string,
  etcAmount: number,
  etcTime: string,
}

const TaskStatistic = ({onLogout}: any) => {

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '번호',
      dataIndex: 'numb',
      key: 'numb',
      width: 100,
      align: 'center',
    },
    {
      title: '서비스명',
      dataIndex: 'serviceName',
      key: 'serviceName',
    },
    {
      title: '상담원명',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: '합계',
      children: [{
        title: '건수',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        render: (text) => <span>{text.toLocaleString()}</span>,
        align: 'center'
      },{
        title: '시간',
        dataIndex: 'totalTime',
        key: 'totalTime',
        align: 'center'
      }]
    },
    {
      title: '전화홍보',
      children: [{
        title: '건수',
        dataIndex: 'marketingAmount',
        key: 'marketingAmount',
        render: (text) => <span>{text.toLocaleString()}</span>,
        align: 'center'
      },{
        title: '시간',
        dataIndex: 'marketingTime',
        key: 'marketingTime',
        align: 'center'
      }]
    },
    {
      title: '여론조사',
      children: [{
        title: '건수',
        dataIndex: 'surveyAmount',
        key: 'surveyAmount',
        render: (text) => <span>{text.toLocaleString()}</span>,
        align: 'center'
      },{
        title: '시간',
        dataIndex: 'surveyTime',
        key: 'surveyTime',
        align: 'center'
      }]
    },
    {
      title: '기타',
      children: [{
        title: '건수',
        dataIndex: 'etcAmount',
        key: 'etcAmount',
        render: (text) => <span>{text.toLocaleString()}</span>,
        align: 'center'
      },{
        title: '시간',
        dataIndex: 'etcTime',
        key: 'etcTime',
        align: 'center'
      }]
    },
  ];

  const data: DataType[] = [
    {
      key: 1,
      numb: '1',
      name: '홍길동',
      serviceName: '파일명1.xlsx',
      totalAmount: 50000,
      totalTime: '1:09:22',
      marketingAmount: 50000,
      marketingTime: '1:09:22',
      surveyAmount: 50000,
      surveyTime: '1:09:22',
      etcAmount: 50000,
      etcTime: '1:09:22'
    }
  ];

  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   req();
  // }, []);

  // const req = async () => {
  //   const res = await fetch(api.getSurveyors, {
  //     method: 'GET',
  //     headers: {
  //       'access-token': localStorage.getItem('access_token') ?? '',
  //     },
  //   });

  //   const json = await res.json();

  //   const users = json.data.surveyors;
    
  //   setData(users.map((u: any, i: number) => ({
  //     key: (users.length - i).toString(),
  //     numb: (users.length - i).toString(),
  //     name: u.user_name,
  //     complete: u.task_completed,
  //     remain: u.task_yet,
  //   })));
  // };

  const [folders, setFolders] = useState<any>([{
      title: '파일1.xlsx',
      _id: 0,
    },{
      title: '파일2.xlsx',
      _id: 1,
    },{
      title: '파일3.xlsx',
      _id: 2,
    },{
      title: '파일4.xlsx',
      _id: 3,
    }]);
    const [selectedFolder, setSelectedFolder] = useState('파일1.xlsx');
    const selectChange = (value: string) => {
      setSelectedFolder(value);
    };

  return (
    <div className='split-frame'>
      <SideNav selected='7' onLogout={onLogout} />
      <div className='split-frame-right-wrap'>
        <div className='split-frame-right scroll-area'>
          <div className='split-frame-title'>
            상담원별 작업현황
          </div>
          <div className='split-frame-title-divide' />
          <div className='body-option-row'>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>파일명</div>
              <div className='body-option-item'>
                <Select
                  style={{ width: 240 }}
                  onChange={selectChange}
                  value={selectedFolder}
                  options={
                    folders.map((folder: any) => {
                      return {
                        label: folder.title,
                        value: folder._id,
                      };
                    })
                  }
                />
              </div>
            </div>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>조회기간</div>
              <div className='body-option-item'>
                <DatePicker onChange={() => console.log('sdf')} placeholder='시작일' /> ~ 
                <DatePicker onChange={() => console.log('sdf')} placeholder='종료일'/>
              </div>
              <div className='body-option-btn-wrap'>
                <Button type="primary" icon={<SearchOutlined />} onClick={() => console.log('sdf')}>검색</Button>
              </div>
            </div>
          </div>
          <div className='divide' />
          <div className='gap8' />
          <Table columns={columns} dataSource={data} bordered />
        </div>
      </div>
    </div>
  );
};

export default TaskStatistic;