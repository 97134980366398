import React, { useState } from 'react';
import SideNav from '../../components/side_nav';
import { Button, Input, Modal, Switch, Table, TableProps, Upload, UploadProps, message } from 'antd';
import { AudioOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';

interface DataType {
  key: number,
  numb: string,
  title: string,
  date: string,
}

const CandVoicePage = ({onLogout}: any) => {

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '번호',
      dataIndex: 'numb',
      key: 'numb',
      width: 100,
      align: 'center',
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <a onClick={() => modalEditOpen()}>{text}</a>,
    },
    {
      title: '사용',
      dataIndex: 'switch',
      key: 'switch',
      width: 180,
      render: (text, record, index) => <Switch checked={isToggle === index} onClick={() => toggleClick(index)} />,
    },
    {
      title: '작성일',
      dataIndex: 'date',
      key: 'date',
      width: 180,
    },
  ];

  const data: DataType[] =  true ?[]:  [
    {
      key: 3,
      numb: '3',
      title: '보이스 3',
      date: '2024-02-21 13:08',
    },
    {
      key: 2,
      numb: '2',
      title: '보이스 2',
      date: '2024-02-21 13:08',
    },
    {
      key: 1,
      numb: '1',
      title: '보이스 1',
      date: '2024-02-21 13:08',
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [modalTitle, setModalTitle] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalOpen = () => {
    setIsModalOpen(true);
  };
  const modalEditOpen = () => {
    setIsModalOpen(true);
    setModalTitle('기존 작성한 제목');
  };
  const modalSubmit = () => {
    setIsModalOpen(false);
    setModalTitle('');
  };
  const modalClose = () => {
    setIsModalOpen(false);
    setModalTitle('');
  };

  const [deleteOpen, setDeleteOpen] = useState(false);
  const deleteModalOpen = () => {
    setDeleteOpen(true);
  };
  const deleteSubmit = () => {
    setDeleteOpen(false);
  };
  const deleteClose = () => {
    setDeleteOpen(false);
  };

  const props: UploadProps = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const [isToggle, setIsToggle] = useState<number>();
  const [tempToggle, setTempToggle] = useState<number|null>();
  const toggleClick = (idx:number) => {
    setTempToggle(idx);
    console.log(idx);
    setToggleModal(true);
  };
  const [toggleModal, setToggleModal] = useState(false);
  const toggleModalOk = (idx: any) => {
    if (idx || idx >= 0) {
      setIsToggle(idx);
      setToggleModal(false);
    } else {
      alert('error');
    }
  };
  const toggleModalCancel = () => {
    setToggleModal(false);
    setTempToggle(null);
  };

  return (
    <div className='split-frame'>
      <Modal
        title="후보자 음성 사용"
        open={toggleModal}
        onOk={toggleModalOk}
        onCancel={toggleModalCancel}
        footer={[
          <Button key="back" onClick={toggleModalCancel}>
            취소
          </Button>,
          <Button key="submit" danger onClick={() => toggleModalOk(tempToggle)}>
            확인
          </Button>
        ]}
      >
        선택한 후보자 음성을 사용합니다.
      </Modal>
      <Modal
        title="보이스 제목"
        open={isModalOpen}
        onOk={modalSubmit}
        onCancel={modalClose}
        footer={[
          <Button key="back" onClick={modalClose}>
            취소
          </Button>,
          <Button key="submit" type='primary' onClick={modalSubmit}>
            저장
          </Button>
        ]}
      >
        <div className='divide' />
          <div className='modal-cand-voice-body'>
            <div className='modal-label'>제목</div>
            <Input placeholder="제목 입력..." value={modalTitle} onChange={(e:React.ChangeEvent<HTMLInputElement>) => setModalTitle(e.target.value)} />
            <div className='modal-label'>업로드</div>
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>보이스 업로드</Button>
            </Upload>
          </div>
        <div className='divide' />
      </Modal>
      <Modal
        title="후보자 보이스 삭제"
        open={deleteOpen}
        onOk={deleteSubmit}
        onCancel={deleteClose}
        footer={[
          <Button key="back" onClick={deleteClose}>
            취소
          </Button>,
          <Button key="submit" danger onClick={deleteSubmit}>
            삭제
          </Button>
        ]}
      >
        선택한 항목을 삭제하시겠습니까?
      </Modal>
      <SideNav selected='3' onLogout={onLogout} />
      <div className='split-frame-right-wrap'>
        <div className='split-frame-right scroll-area'>
          <div className='split-frame-title'>
            후보자 음성 관리
            <div>
              <Button icon={<AudioOutlined />} onClick={() => modalOpen()}>후보자 음성 등록</Button>
            </div>
          </div>
          <div className='divide' />
          <div className='table-btns-wrap'>
            <Button
              icon={<DeleteOutlined />}
              disabled={selectedRowKeys && selectedRowKeys.length > 0 ? false : true}
              onClick={() => deleteModalOpen()}
              danger
            >
              선택 항목 삭제
            </Button>
          </div>
          <Table rowSelection={rowSelection} columns={columns} dataSource={data} bordered />
        </div>
      </div>
    </div>
  );
};

export default CandVoicePage;