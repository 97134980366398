import React, { useEffect, useState } from 'react';
import SideNav from '../../components/side_nav';
import { Button, Input, Modal, Pagination, Select, Table, TableProps, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import api from '../../config/api';

interface DataType {
  key: number,
  numb: string,
  callNumber: string,
  date: string,
  owner: string,
  status: string,
}

interface DetailDataType {
  numb: string,
  callNumber: string,
  date: string,
  owner: string,
  status: string,
}

const ResearchListPage = ({onLogout}: any) => {

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '번호',
      dataIndex: 'numb',
      key: 'numb',
      width: 100,
      align: 'center',
    },
    {
      title: '전화번호',
      dataIndex: 'callNumber',
      key: 'callNumber',
      render: (text, record) => <a onClick={() => modalOpen(record)}>{text}</a>,
    },
    {
      title: '담당자',
      dataIndex: 'owner',
      key: 'owner',
      width: 180,
    },
    {
      title: '마지막 통화',
      dataIndex: 'date',
      key: 'date',
      width: 180,
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text) => text === '완료' ? <Tag color={'blue'} style={{margin: 0}}>{text}</Tag> :
      text === '진행중' ? <Tag color={'orange'} style={{margin: 0}}>{text}</Tag> :
      text ===  '미진행' ? <Tag style={{margin: 0}}>{text}</Tag> : null,
      align: 'center'
    },
  ];

  const detailColumns: TableProps<DetailDataType>['columns'] = [
    {
      title: '번호',
      dataIndex: 'numb',
      key: 'numb',
      width: 100,
      align: 'center',
    },
    {
      title: '전화번호',
      dataIndex: 'callNumber',
      key: 'callNumber',
      render: (text, record) => <a onClick={() => modalOpen(record)}>{text}</a>,
    },
    {
      title: '담당자',
      dataIndex: 'owner',
      key: 'owner',
      width: 180,
    },
    {
      title: '마지막 통화',
      dataIndex: 'date',
      key: 'date',
      width: 180,
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text) => text === '완료' ? <Tag color={'blue'} style={{margin: 0}}>{text}</Tag> :
      text === '진행중' ? <Tag color={'orange'} style={{margin: 0}}>{text}</Tag> :
      text ===  '미진행' ? <Tag style={{margin: 0}}>{text}</Tag> : null,
      align: 'center'
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalOpen = (record: any) => {
    console.log(record);

    setRecords(record.records.map((r: any) => {
      return {
        numb: record.records.length - 1,
        callNumber: record.callNumber,
        date: new Date(r.last_call_time).toLocaleTimeString(),
        owner: record.owner,
        status: record.status,
      };
    }));
    setIsModalOpen(true);
  };
  const modalSubmit = () => {
    setIsModalOpen(false);
  };
  const modalClose = () => {
    setIsModalOpen(false);
  };

  const selectChange = (value: string) => {
    setStatus(value);
  };

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('전체');
  const [keyword, setKeyword] = useState('');
  const [totals, setTotals] = useState(0);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    req();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (status) {
      setPage(1);
      setTimeout(() => {
        req();
      }, 300);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const req = async () => {
    const res = await fetch(`${api.get_record}?keyword=${keyword}&page=${page}&status=${status}`, {
      'method': 'GET',
      'headers': {
        'access-token': localStorage.getItem('access_token') ?? '',
      },
    });

    const json = await res.json();

    setTotals(json.data.totals);
    setData(json.data.users.map((user: any, i: number) => ({
      key: user._id,
      numb: (json.data.totals - ((page - 1) * 20) - i).toString(),
      callNumber: user.phone_number,
      date: !user.last_call_time ? '-' : new Date(user.last_call_time).toLocaleTimeString(),
      owner: !user.assgined_surveyor_id ? '-' : user.assgined_surveyor_id.user_name,
      status: user.status === 'yet' ? '미진행' : user.status === 'calling' ? '진행중' : '완료',
      records: user.record_id,
    })));
  };

  const search = async () => {
    setPage(1);
    setTimeout(() => {
      req();
    }, 300);
  };

  return (
    <div className='split-frame'>
      <Modal
        title="상담 이력"
        open={isModalOpen}
        onOk={modalSubmit}
        onCancel={modalClose}
        footer={[
          <Button key="back" onClick={modalClose}>
            닫기
          </Button>
        ]}
        width={800}
      >
        <div className='divide' />
          <div className='modal-cand-voice-body'>
            <Table columns={detailColumns} dataSource={records} bordered />
          </div>
        <div className='divide' />
      </Modal>
      <SideNav selected='6' onLogout={onLogout} />
      <div className='split-frame-right-wrap'>
        <div className='split-frame-right scroll-area'>
          <div className='split-frame-title'>
            상담이력
          </div>
          <div className='split-frame-title-divide' />
          <div className='body-option-row'>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>상태</div>
              <div className='body-option-item'>
                <Select
                  value={status}
                  style={{ width: 120 }}
                  onChange={selectChange}
                  options={[
                    { value: '전체', label: '전체' },
                    { value: '미진행', label: '미진행' },
                    { value: '진행중', label: '진행중' },
                    { value: '완료', label: '완료' },
                  ]}
                />
              </div>
            </div>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>검색</div>
              <div className='body-option-item'>
                <Input placeholder="전화번호 또는 담당자 검색..." style={{width: 240}} value={keyword} onChange={({target: {value}}) => setKeyword(value)} />
              </div>
              <div className='body-option-btn-wrap'>
                <Button type="primary" icon={<SearchOutlined />} onClick={search}>검색</Button>
              </div>
            </div>
          </div>
          <div className='divide' />
          <div className='gap8' />
          <Table rowSelection={rowSelection} columns={columns} dataSource={data} bordered pagination={false} />
          <div style={{marginTop: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
          <Pagination current={page} onChange={(p) => setPage(p)} total={totals} />
          </div>
        </div>
      </div>
    </div>
  );
};  

export default ResearchListPage;