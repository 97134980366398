import React, { useEffect, useState } from 'react';
import SideNav from '../../components/side_nav';
import { Button, Input, Modal, Select, Table, TableProps, Pagination, Dropdown, Space, MenuProps, Checkbox, CheckboxOptionType } from 'antd';
import { ArrowRightOutlined, DownOutlined, LeftOutlined, SearchOutlined, SwapOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import api from '../../config/api';

interface DataType {
  key: number,
  numb: string,
  callNumber: string,
  owner: string,
  lastCall: string,
}

interface DetailDataType {
  key: number,
  owner: string,
  callNumber: string,
  lastCall: string,
}

const items: MenuProps['items'] = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: '0',
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: '1',
  },
  {
    type: 'divider',
  },
  {
    label: '3rd menu item',
    key: '3',
  },
];

const AssignPage = (props: any) => {
  const {onLogout} = props;

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '번호',
      dataIndex: 'numb',
      key: 'numb',
      width: 100,
      align: 'center',
    },
    {
      title: '전화번호',
      dataIndex: 'callNumber',
      key: 'callNumber',
    },
    {
      title: '담당자',
      dataIndex: 'owner',
      key: 'owner',
    },
    {
      title: '마지막 통화',
      dataIndex: 'lastCall',
      key: 'lastCall',
    },
  ];

  const defaultCheckedList = columns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const options = columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const getOptions = () => {
    let f: any;

    folders.forEach((folder: any) => {
      if (folder._id === selectedFolder) {
        f = folder;
      }
    });

    if (!f || !f.fields) {
      return options;
    }

    return [...options, ...f.fields.map((v: any) => ({
      label: v,
      value: v,
    }))];
  };

  const getColumns = (l: any) => {
    let f: any;

    folders.forEach((folder: any) => {
      if (folder._id === selectedFolder) {
        f = folder;
      }
    });

    if (!f || !f.fields) {
      return columns.map((item) => ({
        ...item,
        hidden: !l.includes(item.key as string),
      }));
    }

    return [
      ...columns.map((item) => ({
        ...item,
        hidden: !l.includes(item.key as string),
      })),
      ...f.fields.map((v: any) => ({
        title: v,
        dataIndex: v,
        key: v,
        hidden: !l.includes(v as string),
      }))
    ];
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalOpen = (record: any) => {
    setSelectedSurveyor(surveyors[0]._id);
    setIsModalOpen(true);
  };
  const modalSubmit = () => {
    changeSurveyor();
    setIsModalOpen(false);
  };
  const modalClose = () => {
    setIsModalOpen(false);
  };

  const selectChange = (value: string) => {
    setSelectedFolder(value);
  };

  const [selectedSurveyor, setSelectedSurveyor] = useState('');
  const selectSurveyor = (value: string) => {
    setSelectedSurveyor(value);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [phoneNumber, setPhoneNumber] = useState('');
  const [folders, setFolders] = useState<any>([]);
  const [surveyors, setSurveyors] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [data, setData] = useState<any>([]);
  const [totals, setTotals] = useState(0);

  useEffect(() => {
    folderReq();
    surveyorReq();
  }, []);

  useEffect(() => {
    if (selectedFolder) {
      setPage(1);
      setTimeout(() => {
        req();
      }, 300);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolder]);

  useEffect(() => {
    req();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const folderReq = async () => {
    const res = await fetch(`${api.getTargetUserFolders}`, {
      'method': 'GET',
      'headers': {
        'access-token': localStorage.getItem('access_token') ?? '',
      },
    });

    const json = await res.json();

    setFolders(json.data.folders.map((folder: any, i: number) => ({
      title: folder.folder_name,
      amount: folder.folder_amount,
      _id: folder._id,
      fields: folder.folder_fields,
    })));
  
    setSelectedFolder(json.data.folders[0]._id);
  };

  const surveyorReq = async () => {
    const res = await fetch(`${api.getSurveyors}`, {
      'method': 'GET',
      'headers': {
        'access-token': localStorage.getItem('access_token') ?? '',
      },
    });

    const json = await res.json();

    setSurveyors(json.data.surveyors);
  };

  const req = async () => {
    let f: any;

    folders.forEach((folder: any) => {
      if (folder._id === selectedFolder) {
        f = folder;
      }
    });

    const res = await fetch(`${api.getTargetUsers}?page=${page}&folderId=${selectedFolder}&phoneNumber=${phoneNumber}`, {
      'method': 'GET',
      'headers': {
        'access-token': localStorage.getItem('access_token') ?? '',
      },
    });

    const json = await res.json();

    const us = json.data.users;
    setData(us.map((u: any, i: number) => {
      const d: any = {
        key: u._id,
        numb: (getTotals() - ((page - 1) * 20) - i).toString(),
        fileName: u.folder_id.folder_name,
        callNumber: u.phone_number,
        owner: !u.assgined_surveyor_id ? '-' : u.assgined_surveyor_id.user_name,
        lastCall: u.record_id.length === 0 ? '-' : new Date(u.last_call_time).toLocaleDateString(),
      };

      f.fields.forEach((field: any) => {
        d[field] = u.data[field];
      });

      return d;
    }));

    setTotals(json.data.totals);
  };

  const search = async () => {
    setPage(1);
    setTimeout(() => {
      req();
    }, 300);
  };

  const getTotals = () => {
    const [tf] = folders.filter((v: any) => v._id === selectedFolder);

    if (!tf) {
      return 0;
    }

    return tf.amount;
  };

  const changeSurveyor = async () => {
    await fetch(`${api.assignSurveyorToTargets}`, {
      'method': 'POST',
      'headers': {
        'access-token': localStorage.getItem('access_token') ?? '',
        'Content-Type': 'application/json',
      },
      'body': JSON.stringify({
        targetIds: selectedRowKeys,
        surveyorId: selectedSurveyor,
      }),
    });

    setSelectedSurveyor('');
    setSelectedRowKeys([]);

    req();
  };

  return (
    <div className='split-frame'>
      <Modal title="담당자 변경" open={isModalOpen} onOk={modalSubmit} onCancel={modalClose}>
        <div className='divide' />
        {/* {
          selectedRowKeys.length > 1 ?
            <div /> :
            <Table columns={detailColumns} dataSource={detailData} bordered pagination={false} />
        } */}
        <div className='gap24' />
        <div className='selected-group-info'>
          <UserOutlined />
          변경할 담당자
        </div>
        <div className='change-owner-wrap'>
          {/* <Select
            defaultValue="홍길동"
            style={{ width: 120 }}
            onChange={selectSurveyor}
            options={surveyors.map((s: any) => ({
              value: s._id,
              label: s.user_name,
            }))}
            disabled
          />
          <ArrowRightOutlined /> */}
          <Select
            value={selectedSurveyor}
            style={{ width: 120 }}
            onChange={selectSurveyor}
            options={surveyors.map((s: any) => ({
              value: s._id,
              label: s.user_name,
            }))}
          />
        </div>
        <div className='divide' />
      </Modal>
      <SideNav selected='1' onLogout={onLogout} />
      <div className='split-frame-right-wrap'>
        <div className='split-frame-right scroll-area'>
          <div className='split-frame-title'>
            상담원별 유권자 할당
          </div>
          <div className='split-frame-title-divide' />
          <div className='body-option-row'>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>파일명</div>
              <div className='body-option-item'>
                <Select
                  style={{ width: 240 }}
                  onChange={selectChange}
                  value={selectedFolder}
                  options={
                    folders.map((folder: any) => {
                      return {
                        label: folder.title,
                        value: folder._id,
                      };
                    })
                  }
                />
              </div>
            </div>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>전화번호</div>
              <div className='body-option-item'>
                <Input placeholder="전화번호 검색..." style={{width: 240}} value={phoneNumber} onChange={({target: {value}}) => setPhoneNumber(value)} />
              </div>
              <div className='body-option-btn-wrap'>
                <Button type="primary" icon={<SearchOutlined />} onClick={() => search()}>검색</Button>
              </div>
            </div>
          </div>
          <div className='divide' />
          <div className='gap8' />
          <div className='table-btns-wrap'>
            <Button
              icon={<SwapOutlined />}
              disabled={selectedRowKeys && selectedRowKeys.length > 0 ? false : true}
              onClick={() => modalOpen('')}
            >
              선택 항목 담당자 변경
            </Button>
          </div>
          <div className='gap8' />
          <Checkbox.Group
            value={checkedList}
            options={getOptions() as CheckboxOptionType[]}
            onChange={(value) => {
              setCheckedList(value as string[]);
            }}
          />
          <div className='gap24' />
          <Table rowSelection={rowSelection} columns={getColumns(checkedList)} dataSource={data} bordered pagination={false} />
          <div style={{marginTop: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
          <Pagination current={page} onChange={(p) => setPage(p)} total={totals} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignPage;