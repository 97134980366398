import React from 'react';

const DownloadPage = () => {
  return (
    <div className='download-page-frame'>
      <div className='download-title'>AI-ESC 다운로드</div>
      <div className='download-items-wrap'>
        <div className='download-item-column'>
          <div className='download-item'>
            <img src='/img/download-android_.png' alt='android' />
          </div>
          <div className='download-btns-wrap'>
            <div className='download-btn' onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/touchcall2022.appspot.com/o/aiesc%2Faiesc.apk?alt=media&token=d268f413-a2ee-4565-ba43-971a8ecd83d6')}>설치파일 다운로드</div>
            {/* <div className='download-btn'>매뉴얼 다운로드</div> */}
          </div>
        </div>
        {/* <div className='download-item-column'>
          <div className='download-item'>
            <img src='/img/download-iOS.png' alt='iOS' />
          </div>
          <div className='download-btns-wrap'>
            <div className='download-btn'>설치파일 다운로드</div>
            <div className='download-btn'>매뉴얼 다운로드</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DownloadPage;