export const endpoint = 'https://aiesc-api.touchcall.app:4000';
// export const endpoint = 'http://172.30.1.10:4000';


const api = {
  'issueJWT': `${endpoint}/api/auth/issue_jwt`,
  'revokeJWT': `${endpoint}/api/auth/revoke_jwt`,
  'validateJWT': `${endpoint}/api/auth/validate_jwt`,
  'getAdmins': `${endpoint}/api/user/get_admins`,
  'changePermission': `${endpoint}/api/user/change_permission`,
  'getSurveyors': `${endpoint}/api/user/get_surveyors`,
  'getTargetUserFolders': `${endpoint}/api/survey/get_target_user_folders`,
  'uploadExcel': `${endpoint}/api/survey/upload_excel`,
  'deleteTargetUserFolder': `${endpoint}/api/survey/delete_target_user_folder`,
  'getTargetUsers': `${endpoint}/api/survey/get_target_users`,
  'assignSurveyorToTargets': `${endpoint}/api/survey/assign_surveyor_to_targets`,
  'get_candidate_info': `${endpoint}/api/survey/get_candidate_info`,
  'create_candidate_infos': `${endpoint}/api/survey/create_candidate_infos`,
  'update_candidate_infos': `${endpoint}/api/survey/update_candidate_infos`,
  'delete_candidate_infos': `${endpoint}/api/survey/delete_candidate_infos`,
  'get_record': `${endpoint}/api/survey/get_record`,
  'get_surveyor_record': `${endpoint}/api/survey/get_surveyor_record`,
  'update_survey': `${endpoint}/api/survey/update_survey`,
  'create_record': `${endpoint}/api/survey/create_record`,
  'get_camp_info': `${endpoint}/api/camp/get_camp_info`,
  'add_voice': `${endpoint}/api/ai/add_ai_voice`,
  'add_text': `${endpoint}/api/ai/add_ai_text`,
};

export default api;
