import {DeploymentUnitOutlined, PlayCircleFilled} from '@ant-design/icons';
import {Button, Modal, Table, TableProps} from 'antd';
import {useEffect, useRef, useState} from 'react';
import SideNav from '../../components/side_nav';
import TextArea from 'antd/es/input/TextArea';
import api, {endpoint} from '../../config/api';

interface DataType {
  key: number,
  numb: string,
  title: string,
  date: string,
}

const AiVoicePage = ({onLogout}: any) => {
  const voiceColumns: TableProps<DataType>['columns'] = [
    {
      title: '번호',
      dataIndex: 'numb',
      key: 'numb',
      width: 100,
      align: 'center',
    },
    {
      title: '보이스 아이디',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <div>{text}</div>,
    },
    {
      title: '작성일',
      dataIndex: 'date',
      key: 'date',
      width: 180,
    },
  ];

  const textColumns: any = [
    {
      title: '번호',
      dataIndex: 'numb',
      key: 'numb',
      width: 100,
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'title',
      key: 'title',
      width: 180,
      render: (text: any) => <div>{text === '7mmS2k9Nu5dY3nXN1Uef' ? '박진' : '조국'}</div>,
    },
    {
      title: '텍스트',
      dataIndex: 'text',
      key: 'text',
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: '작성일',
      dataIndex: 'date',
      key: 'date',
      width: 180,
    },
    {
      title: '재생',
      dataIndex: 'play',
      key: 'play',
      width: 100,
      render: (d: any) => {
        console.log(d);
        return (
          <div>
            <Button icon={<PlayCircleFilled />} onClick={() => {
              if (isAudioPlay) {
                audioRef.current.pause();
                setIsAudioPlay(false);
              } else {
                const fn = d.split('/')[d.split('/').length - 1];
                console.log(`${endpoint}/${fn}`);
                audioRef.current = new Audio(`${endpoint}/${fn}`);
                audioRef.current.play();
                setIsAudioPlay(true);
              }
            }}></Button>
          </div>
        );
      }
    },
    {
      title: '다운로드',
      dataIndex: 'play',
      key: 'play',
      width: 100,
      render: (d: any) => {
        console.log(d);
        const fn = d.split('/')[d.split('/').length - 1];

        return (
          <div>
            <a target="_blank" onClick={() => {
              const url = `${endpoint}/${fn}`;
              console.log(url);
                fetch(url)
                .then(res => res.blob())
                .then(res => {
                  console.log(res);

                  const aElement = document.createElement('a');
                  aElement.setAttribute('download', fn);
                  const href = URL.createObjectURL(res);
                  aElement.href = href;
                  aElement.setAttribute('target', '_blank');
                  aElement.click();
                  URL.revokeObjectURL(href);
                });
            }}>다운로드</a>
          </div>
        );
      }
    },
  ];

  const audioRef = useRef<any>();
  const [isAudioPlay, setIsAudioPlay] = useState(false);

  const modalClose = () => {
    setIsTTSeModalOpen(false);
  };

  const [isTTSModalOpen, setIsTTSeModalOpen] = useState(false);

  const handleFile = async (e: any) => {
    const files = e.target.files;
    if (files.length === 0) {
      return;
    }

    const file = files[0];
    if (!file.type.includes('audio')) {
      alert('오디오 파일을 업로드해주세요');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    await fetch(`${api.add_voice}`, {
      method: 'POST',
      headers: {
        'access-token': localStorage.getItem('access_token') ?? '',
      },
      body: formData,
    });

    req();
  };

  const [voiceInfo, setVoiceInfo] = useState<any>(null);
  const [ttsInfo, setTtsInfo] = useState<any>(null);

  useEffect(() => {
    req();
  }, []);

  const req = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      return;
    }

    const res = await fetch(api.get_camp_info, {
      method: 'GET',
      'headers': {
        'access-token': accessToken ?? '',
      },
    });

    const isSuccess = res.status === 200;

    if (!isSuccess) {
      return;
    }

    const json = await res.json();

    console.log(json);
    const data = json.data.camp;

    if (data.camp_candidate_voice_id && data.camp_candidate_voice_id.length > 0) {
      setVoiceInfo({
        voiceId: data.camp_candidate_voice_id,
        date: data.camp_candidate_voice_updated,
      });
    }

    if (data.camp_candidate_tts_text) {
      setTtsInfo({
        url: data.camp_candidate_tts_url,
        text: data.camp_candidate_tts_text,
        date: data.camp_candidate_tts_updated,
      });
    }
  };

  const [content, setContent] = useState('');

  useEffect(() => {
    setContent('');
  }, [isTTSModalOpen]);

  const addText = async () => {
    if (content.length === 0) {
      alert('내용을 입력해주세요');
      return;
    }

    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      return;
    }

    await fetch(api.add_text, {
      method: 'POST',
      'headers': {
        'access-token': accessToken ?? '',
        'Content-Type': 'application/json',
      },
      'body': JSON.stringify({
        text: content,
      })
    });

    setIsTTSeModalOpen(false);

    req();
  };

  return (
    <div className='split-frame'>
      <input type="file" id="file" onChange={handleFile} style={{display: 'none'}} />
      <Modal
        title="AI 텍스트 등록/수정"
        open={isTTSModalOpen}
        onOk={undefined}
        onCancel={modalClose}
        footer={[
          <Button key="back" onClick={modalClose}>
            취소
          </Button>,
          <Button key="submit" type='primary' onClick={addText}>
            저장
          </Button>
        ]}
      >
        <div className='divide' />
          <div className='modal-cand-voice-body'>
            <div className='modal-label'>텍스트</div>
            <TextArea placeholder="제목 입력..." value={content} onChange={({target: {value}}) => setContent(value)} />
          </div>
        <div className='divide' />
      </Modal>
      <SideNav selected='4' onLogout={onLogout} />
      <div className='split-frame-right-wrap'>
        <div className='split-frame-right scroll-area'>
          <div className='split-frame-title'>
            AI 음성 관리
          </div>
          <div className='divide' />
          <div className='table-btns-wrap2'>
            <Button icon={<DeploymentUnitOutlined />} onClick={() => document.getElementById('file')?.click()}>후보자 음성 등록/수정</Button>
          </div>
          <Table columns={voiceColumns} dataSource={
            !voiceInfo ? [] : [{
              'key': 1,
              'date': new Date(voiceInfo.date).toLocaleDateString(),
              'numb': '1',
              'title': voiceInfo.voiceId,
            }]} bordered />
          <div className='divide' />
          <div className='table-btns-wrap2'>
            <Button icon={<DeploymentUnitOutlined />} onClick={() => setIsTTSeModalOpen(true)}>AI 텍스트 등록/수정</Button>
          </div>
          <Table columns={textColumns} dataSource={
            !ttsInfo ? [] : [{
              'key': 1,
              'date': new Date(ttsInfo.date).toLocaleDateString(),
              'numb': '1',
              'title': voiceInfo.voiceId,
              'text': ttsInfo.text,
              'play': ttsInfo.url,
            }]
          } bordered />
        </div>
      </div>
    </div>
  );
};

export default AiVoicePage;