import React, { useEffect, useState } from 'react';
import SideNav from '../../components/side_nav';
import { Button, Modal, Select, Table, TableProps } from 'antd';
import { ArrowRightOutlined, KeyOutlined } from '@ant-design/icons';
import api from '../../config/api';

interface DataType {
  key: number,
  numb: string,
  name: string,
  id: string,
  permission: string,
}

interface DetailDataType {
  key: number,
  name: string,
  id: string,
  permission: string,
}

const detailColumns: TableProps<DetailDataType>['columns'] = [
  {
    title: '이름',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '권한',
    dataIndex: 'permission',
    key: 'permission',
  },
  {
    title: '계정',
    dataIndex: 'id',
    key: 'id',
  },
];

const AccountPage = ({onLogout}: any) => {

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '번호',
      dataIndex: 'numb',
      key: 'numb',
      width: 100,
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '권한',
      dataIndex: 'permission',
      key: 'permission',
    },
    {
      title: '계정',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '권한 변경',
      key: 'action',
      render: (_, record) => (
        <Button type="link" onClick={() => {
          modalOpen(record);
        }}>
          변경
        </Button>
      ),
      width: 100,
      align: 'center',
    },
  ];

  const [data, setData] = useState([]);
  const [detailData, setDetailData] = useState([{
    key: '1',
    name: '',
    id: '',
    permission: '',
  }]) as any;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalOpen = (record: any) => {
    setDetailData([{
      key: '1',
      name: record.name,
      id: record.id,
      permission: record.permission,
    }]);

    setIsModalOpen(true);
  };
  const modalSubmit = async () => {
    await fetch(api.changePermission, {
      method: 'POST',
      headers: {
        'access-token': localStorage.getItem('access_token') ?? '',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'adminId': detailData[0].id,
        'type': selectType,
      }),
    });

    setIsModalOpen(false);

    req();
  };

  const modalClose = () => {
    setIsModalOpen(false);
    setDetailData(false);
  };

  const [selectType, setSelectType] = useState('');
  const selectChange = (value: string) => {
    setSelectType(value);
  };

  useEffect(() => {
    req();
  }, []);

  const req = async () => {
    const res = await fetch(api.getAdmins, {
      method: 'GET',
      headers: {
        'access-token': localStorage.getItem('access_token') ?? '',
      }
    });
  
    const json = await res.json();
    const users = json.data.admins;
    
    setData(users.map((u: any, i: number) => ({
      key: (users.length - i).toString(),
      numb: (users.length - i).toString(),
      name: u.user_name,
      id: u.id,
      permission: u.user_type === 'admin' ? '관리자' : u.user_type === 'candidate' ? '후보자' : '상담원',
    })))
  };

  return (
    <div className='split-frame'>
      {
        isModalOpen ? <Modal
        title="계정 권한 변경"
        open={isModalOpen}
        onOk={modalSubmit}
        onCancel={modalClose}
        footer={[
          <Button key="back" onClick={modalClose}>
            닫기
          </Button>,
          <Button key="save" type='primary' onClick={modalSubmit}>
            저장
          </Button>
        ]}
      >
        <div className='divide' />
        <Table columns={detailColumns} dataSource={detailData} bordered pagination={false} />
        <div className='gap24' />
        <div className='selected-group-info'>
          <KeyOutlined />
          권한 변경
        </div>
        <div className='change-owner-wrap'>
          <Select
            defaultValue={detailData[0].permission}
            style={{ width: 120 }}
            onChange={selectChange}
            options={[
              { value: 'surveyor', label: '상담원' },
              { value: 'admin', label: '관리자' },
              { value: 'candidate', label: '후보자' },
            ]}
            disabled
          />
          <ArrowRightOutlined />
          <Select
            defaultValue={detailData[0].permission}
            style={{ width: 120 }}
            onChange={selectChange}
            options={[
              { value: 'surveyor', label: '상담원' },
              { value: 'admin', label: '관리자' },
              { value: 'candidate', label: '후보자' },
            ]}
          />
        </div>
        <div className='divide' />
      </Modal> : (<div />)
      }
      
      <SideNav selected='10' onLogout={onLogout} />
      <div className='split-frame-right-wrap'>
        <div className='split-frame-right scroll-area'>
          <div className='split-frame-title'>
            ID 관리
          </div>
          <Table columns={columns} dataSource={data} bordered />
        </div>
      </div>
    </div>
  );
};

export default AccountPage;