import SideNav from '../../components/side_nav';
import { Button, Checkbox, CheckboxProps, Divider, GetProp, Input, Modal, Table, TableProps, Radio } from 'antd';
import { SearchOutlined, UserSwitchOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import api from '../../config/api';
import readXlsxFile from 'read-excel-file'

interface DataType {
  numb: string,
  title: string,
  fileSize: string,
  amount: string,
  _id: string,
}

type CheckboxValueType = GetProp<typeof Checkbox.Group, 'value'>[number];

const CheckboxGroup = Checkbox.Group;

const plainOptions = ['상담사1', '상담사2', '상담사3'];
const defaultCheckedList = ['상담사1', '상담사2', '상담사3'];

const VoterRegisterPage = (props: any) => {
  const {onLogout} = props;

  const [tempKeyword, setTempKeyword] = useState('');
  const [keyword, setKeyword] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    req();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  const req = async () => {
    const res = await fetch(`${api.getTargetUserFolders}?keyword=${keyword}`, {
      'method': 'GET',
      'headers': {
        'access-token': localStorage.getItem('access_token') ?? '',
      },
    });

    const json = await res.json();

    setData(json.data.folders.map((folder: any, i: number) => ({
      numb: (json.data.folders.length - i).toString(),
      title: folder.folder_name,
      fileSize: folder.file_size,
      amount: folder.folder_amount,
      _id: folder._id,
    })));
  };

  const [file, setFile] = useState<any>(null);
  const handleFile = (e: any) => {
    if (e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];

    const ext = file.name.split('.')[file.name.split('.').length - 1];
    if (ext !== 'xlsx' && ext !== 'xls') {
      alert('엑셀 파일을 올려주세요 (허용확장자: xlsx, xls)');
      return;
    }
  
    setFile(file);
  }

  const uploadFile = async () => {
    if (file) {
      const ext = file.name.split('.')[file.name.split('.').length - 1];
      if (ext !== 'xlsx' && ext !== 'xls') {
        alert('엑셀 파일을 올려주세요 (허용확장자: xlsx, xls)');
        return;
      }

      const formData  = new FormData();
      formData.append('file', file);
      formData.append('fields', fields);
      formData.append('pnField', pnField);

      await fetch(`${api.uploadExcel}`, {
        'method': 'POST',
        'headers': {
          'access-token': localStorage.getItem('access_token') ?? '',
        },
        body: formData,
      });

      req();
    }
  };

  const deleteFolder = async (folderId: string) => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      await fetch(`${api.deleteTargetUserFolder}`, {
        'method': 'POST',
        'headers': {
          'access-token': localStorage.getItem('access_token') ?? '',
          'Content-Type': 'application/json',
        },
        'body': JSON.stringify({
          folderId,
        }),
      });

      req();
    }
  };

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '번호',
      dataIndex: 'numb',
      key: 'numb',
      width: 100,
      align: 'center',
    },
    {
      title: 'DB제목',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <a>{text}</a>,
    },
    {
      title: '파일크기',
      dataIndex: 'fileSize',
      key: 'fileSize',
      width: 160,
    },
    {
      title: '자료수',
      dataIndex: 'amount',
      key: 'amount',
      width: 160,
    },
    {
      title: '삭제',
      key: 'action',
      render: (_, record) => (
        <Button danger type="text" onClick={() => deleteFolder(record._id)}>
          삭제
        </Button>
      ),
      width: 100,
      align: 'center',
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalOpen = () => {
    setIsModalOpen(true);
  };
  const modalSubmit = () => {
    uploadFile();
    setIsModalOpen(false);
  };
  const modalClose = () => {
    setIsModalOpen(false);
  };

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);

  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };

  const [fields, setFields] = useState<any>([]);
  const [pnField, setPnField] = useState<any>('');

  useEffect(() => {
    if (file) {
      readXlsxFile(file).then((rows) => {
        setFields(rows[0]);
      })
    } else {
      setFields([]);
      setPnField('');
    }
  }, [file]);

  return (
    <div className='split-frame'>
      <Modal
        title="유권자 할당"
        open={isModalOpen}
        onOk={modalSubmit}
        onCancel={modalClose}
        footer={[
          <Button key="back" onClick={modalClose}>
            취소
          </Button>,
          <Button key="submit" type='primary' onClick={modalSubmit}>
            할당
          </Button>
        ]}>
        <div className='divide' />
        {/* {
          selectedRowKeys.length > 1 ?
            <div /> :
            <Table columns={detailColumns} dataSource={detailData} bordered pagination={false} />
        } */}
        <div className='uploaded-file-name' style={{cursor: 'pointer'}} onClick={() => document.getElementById('file')?.click()}>
          {file ? file.name : '파일 업로드를 위해 클릭하세요'}
        </div>
        {
          !file ? null : (
            <div style={{margin: '20px 0px'}}>
              <div className='selected-group-info'>
                <UserSwitchOutlined />
                번호 필드 선택
              </div>
              <div className='select-assign-wrap'>
                <Radio.Group onChange={(e) => {
                  setPnField(e.target.value);
                }} value={pnField}>
                  {
                    fields.map((f: any, i: any) => {
                      return (
                        <Radio value={f}>{f}</Radio>
                      );
                    })
                  }
                </Radio.Group>
              </div>
            </div>
          )
        }
        <div className='selected-group-info'>
          <UserSwitchOutlined />
          유권자 자동할당
        </div>
        <div className='select-assign-wrap'>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            전체선택
          </Checkbox>
          <Divider />
          <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
        </div>
        <div className='divide' />
      </Modal>
      <SideNav selected='0' onLogout={onLogout} />
      <div className='split-frame-right-wrap'>
        <div className='split-frame-right scroll-area'>
          <div className='split-frame-title'>
            유권자 등록
            <div>
              {/* <Button onClick={() => window.open('/excel/유권자_업로드_템플릿.xlsx')} style={{marginRight: '12px', backgroundColor: '#0ba32c', color: 'white', fontWeight: 'bold'}}>업로드 템플릿 다운로드</Button> */}
              <input type="file" id="file" style={{display: 'none'}} onChange={handleFile} />
              {/* <Button icon={<UsergroupAddOutlined />} onClick={() => document.getElementById('file')?.click()}>유권자 등록</Button> */}
              <Button icon={<UsergroupAddOutlined />} onClick={() => modalOpen()}>유권자 등록</Button>
            </div>
          </div>
          <div className='split-frame-title-divide' />
          <div className='body-option-row'>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>제목 검색</div>
              <div className='body-option-item'>
                <Input placeholder="제목 검색..." style={{width: 240}} value={tempKeyword} onChange={({target: {value}}) => setTempKeyword(value)} />
              </div>
              <div className='body-option-btn-wrap'>
                <Button type="primary" icon={<SearchOutlined />} onClick={() => setKeyword(tempKeyword)}>검색</Button>
              </div>
            </div>
          </div>
          <Table columns={columns} dataSource={data} bordered />
        </div>
      </div>
    </div>
  );
};

export default VoterRegisterPage;