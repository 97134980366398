import { Button, Input, Modal } from 'antd';
import React, { useState } from 'react';

const LoginPage = (props: any) => {
  const {onLogin} = props;

  const [idValue, setIdValue] = useState('');
  const [pwValue, setPwValue] = useState('');
  const onIdChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setIdValue(e.target.value);
  };
  const onPwChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPwValue(e.target.value);
  };
  const login = () => {
    if (idValue.length > 0 && pwValue.length > 0) {
      onLogin(idValue, pwValue, () => {}, openModal);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };
  const modalCancel = () => {
    setModalOpen(false);
  };

  const loginEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      login();
    }
  };

  return (
    <div className='full-frame'>
      <Modal
        title="계정 정보 확인"
        open={modalOpen}
        onOk={modalCancel}
        onCancel={modalCancel}
        footer={[
          <Button key="back" onClick={modalCancel}>
            닫기
          </Button>
        ]}
      >
        아이디 또는 비밀번호를 잘못 입력하셨습니다
      </Modal>
      <div className='login-wrap'>
        <div className='logo-text'>
          <img src='/img/logo.png' alt='logo' />
        </div>
        <Input
          placeholder="아이디"
          size='large'
          value={idValue}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => onIdChange(e)}
          onKeyDown={loginEnter}
        />
        <Input
          placeholder="비밀번호"
          size='large'
          type='password'
          value={pwValue}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => onPwChange(e)}
          onKeyDown={loginEnter}
        />
        <Button type="primary" style={{width: 280}} size='large' onClick={() => login()}>로그인</Button>
      </div>
    </div>
  );
};

export default LoginPage;