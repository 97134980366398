import React, { useEffect, useState } from 'react';
import SideNav from '../../components/side_nav';
import { Button, Modal, Table, TableProps, Input } from 'antd';
import { DeleteOutlined, FileAddOutlined,  } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import api from '../../config/api';

interface DataType {
  key: number,
  numb: string,
  title: string,
  date: string,
  content: string,
}

const CandInfoPage = (props: any) => {
  const {onLogout} = props;

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '번호',
      dataIndex: 'numb',
      key: 'numb',
      width: 100,
      align: 'center',
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <a onClick={() => createModalOpen(String(record.key), record.title, record.content)}>{text}</a>,
    },
    {
      title: '작성일',
      dataIndex: 'date',
      key: 'date',
      width: 180,
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalOpen = (selectId: string | undefined, title: string | undefined, content: string | undefined) => {
    if (selectId) {
      setSelectedId(selectId);
      setTitle(title ?? '');
      setContent(content ?? '');
    }
  
    setIsModalOpen(true);
  };
  const modalSubmit = () => {
    setIsModalOpen(false);
  };
  const modalClose = () => {
    setIsModalOpen(false);
  };

  const [createOpen, setCreateOpen] = useState(false);

  useEffect(() => {
    if (!createOpen) {
      setSelectedId('');
      setTitle('');
      setContent('');
    }
  }, [createOpen]);

  const [createValue, setCreateValue] = useState('');
  const createModalOpen = (selectId: string | undefined, title: string | undefined, content: string | undefined) => {
    if (selectId) {
      setSelectedId(selectId);
      setTitle(title ?? '');
      setContent(content ?? '');
    }
  
    setCreateOpen(true);
  };
  const createModalSubmit = () => {
    if (selectedId) {
      updateInfo();
    } else {
      createInfo();
    }

    if (title.length > 0 && content.length > 0) {
      setCreateOpen(false);
    }
  };
  const createModalClose = () => {
    setCreateOpen(false);
    setCreateValue('');
  };

  const [deleteOpen, setDeleteOpen] = useState(false);
  const deleteModalOpen = () => {
    setDeleteOpen(true);
  };
  const deleteSubmit = () => {
    deleteInfos();
    setDeleteOpen(false);
  };
  const deleteClose = () => {
    setDeleteOpen(false);
  };

  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedId, setSelectedId] = useState('');
  useEffect(() => {
    req();
  }, []);

  const req = async () => {
    const res = await fetch(`${api.get_candidate_info}`, {
      'method': 'GET',
      'headers': {
        'access-token': localStorage.getItem('access_token') ?? '',
      },
    });

    const json = await res.json();

    setData(json.data.infos.map((info: any, i: number) => ({
      key: info._id,
      numb: json.data.infos.length - i,
      title: info.title,
      content: info.content,
      date: new Date(info.created).toLocaleDateString(),
    })));
  };

  const createInfo = async () => {
    if (title.length > 0 && content.length > 0) {
      await fetch(`${api.create_candidate_infos}`, {
        'method': 'POST',
        'headers': {
          'access-token': localStorage.getItem('access_token') ?? '',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title,
          content,
        }),
      });

      setTitle('');
      setContent('');

      req();
    }
  };

  const updateInfo = async () => {
    console.log('update');
    if (title.length > 0 && content.length > 0) {
      await fetch(`${api.update_candidate_infos}`, {
        'method': 'POST',
        'headers': {
          'access-token': localStorage.getItem('access_token') ?? '',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          title,
          content,
          infoId: selectedId,
        }),
      });

      setSelectedId('');
      setTitle('');
      setContent('');

      req();
    }
  };

  const deleteInfos = async () => {
    if (selectedRowKeys.length > 0) {
      await fetch(`${api.delete_candidate_infos}`, {
        'method': 'POST',
        'headers': {
          'access-token': localStorage.getItem('access_token') ?? '',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          infoIds: selectedRowKeys,
        }),
      });

      req();
    }
  };

  return (
    <div className='split-frame'>
      <Modal title="제목" open={isModalOpen} onOk={modalSubmit} onCancel={modalClose}>
        <div className='divide' />
          기록한 내용
        <div className='divide' />
      </Modal>
      <Modal
        title="후보자 정보 등록"
        open={createOpen}
        onOk={createModalSubmit}
        onCancel={createModalClose}
        footer={[
          <Button key="back" onClick={createModalClose}>
            취소
          </Button>,
          <Button key="submit" type='primary' onClick={createModalSubmit}>
            저장
          </Button>
        ]}
      >
        <div className='divide' />
        <Input placeholder='제목 입력...' value={title} onChange={({target: {value}}) => setTitle(value)} />
        <div className='divide' />
          <TextArea
            onChange={({target: {value}}) => setContent(value)}
            value={content}
            placeholder="정보 입력..."
            style={{ height: 360, resize: 'none' }}
          />
        <div className='divide' />
      </Modal>
      <Modal
        title="후보자 정보 삭제"
        open={deleteOpen}
        onOk={deleteSubmit}
        onCancel={deleteClose}
        footer={[
          <Button key="back" onClick={deleteClose}>
            취소
          </Button>,
          <Button key="submit" danger onClick={deleteSubmit}>
            삭제
          </Button>
        ]}
      >
        선택한 항목을 삭제하시겠습니까?
      </Modal>
      <SideNav selected='2' onLogout={onLogout} />
      <div className='split-frame-right-wrap'>
        <div className='split-frame-right scroll-area'>
          <div className='split-frame-title'>
            후보자 정보 관리
            <div>
              <Button icon={<FileAddOutlined />} onClick={() => createModalOpen(undefined, undefined, undefined)}>후보자 정보 등록</Button>
            </div>
          </div>
          <div className='divide' />
          <div className='table-btns-wrap'>
            <Button
              icon={<DeleteOutlined />}
              disabled={selectedRowKeys && selectedRowKeys.length > 0 ? false : true}
              onClick={() => deleteModalOpen()}
              danger
            >
              선택 항목 삭제
            </Button>
          </div>
          <Table rowSelection={rowSelection} columns={columns} dataSource={data} bordered />
        </div>
      </div>
    </div>
  );
};

export default CandInfoPage;