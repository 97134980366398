import React, { Dispatch, SetStateAction, useState } from 'react';
import SideNav from '../../components/side_nav';
import { Button, Input, Modal, Pagination, PaginationProps, Radio, RadioChangeEvent, Select, Space, Table, TableProps, Tag } from 'antd';
import { CloseOutlined, LogoutOutlined, PhoneOutlined, PhoneTwoTone, SearchOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';
import routes from '../../config/routes';
import CallPush from '../../components/call';

interface DataType {
  key: number,
  numb: string,
  callNumber: string,
  lastCall: string,
  status: string,
  answer: string,
}

interface DetailDataType {
  key: number,
  callNumber: string,
  lastCall: string,
  status: string,
}

interface MobilePushData {
  columns: any[];
  data: any[];
  radioChange: (e:RadioChangeEvent) => void;
  radioValue: number;
  textChange: (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  textareaValue: string;
  setOpenState: Dispatch<SetStateAction<boolean>>;
}

const VoterListPage = (props: any) => {
  const {onLogout} = props;

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '번호',
      dataIndex: 'numb',
      key: 'numb',
      width: 100,
      align: 'center',
    },
    {
      title: '전화번호',
      dataIndex: 'callNumber',
      key: 'callNumber',
      render: (text) => <a onClick={() => setDetailModal(true)}>{text}</a>
    },
    {
      title: '마지막 통화',
      dataIndex: 'lastCall',
      key: 'lastCall',
      width: 180,
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text) => text === '완료' ? <Tag color={'blue'} style={{margin: 0}}>{text}</Tag> :
      text === '진행중' ? <Tag color={'orange'} style={{margin: 0}}>{text}</Tag> :
      text ===  '미진행' ? <Tag style={{margin: 0}}>{text}</Tag> : null,
      align: 'center'
    },
    {
      title: '답변',
      dataIndex: 'answer',
      key: 'answer',
      width: 100,
      render: (text) => text === '긍정' ? <Tag color="#30aaff" style={{margin: 0}}>{text}</Tag> :
      text === '부정' ? <Tag color="#f50" style={{margin: 0}}>{text}</Tag> :
      text ===  '타후보' ? <Tag color="#30bb60" style={{margin: 0}}>{text}</Tag> :
      text ===  '기타' ? <Tag color="#888"  style={{margin: 0}}>{text}</Tag> : null,
      align: 'center'
    },
    // {
    //   title: '통화',
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: 72,
    //   render: (text, record, index) =>
    //   text === '미진행' ? <div className='call-btn-wrap' onClick={() => setCalling(true)}><PhoneTwoTone /></div> :
    //   text === '진행중' ? <div className='call-btn-wrap' onClick={() => setCalling(true)}><PhoneTwoTone /></div> :
    //   text === '완료' ? <div className='call-btn-wrap' onClick={() => setCalling(true)}><PhoneTwoTone /></div> : '-',
    //   align: 'center'
    // },
  ];

  const data: DataType[] = [
    {
      key: 12,
      numb: '12',
      callNumber: '010-1234-1234',
      lastCall: '-',
      status: '완료',
      answer: '긍정'
    },
    {
      key: 11,
      numb: '11',
      callNumber: '010-1234-1234',
      lastCall: '2024-02-21 13:08',
      status: '완료',
      answer: '긍정'
    },
    {
      key: 10,
      numb: '10',
      callNumber: '010-1234-1234',
      lastCall: '2024-02-21 13:08',
      status: '완료',
      answer: '긍정'
    },
    {
      key: 9,
      numb: '9',
      callNumber: '010-1234-1234',
      lastCall: '-',
      status: '완료',
      answer: '긍정'
    },
    {
      key: 8,
      numb: '8',
      callNumber: '010-1234-1234',
      lastCall: '2024-02-21 13:08',
      status: '완료',
      answer: '긍정'
    },
    {
      key: 7,
      numb: '7',
      callNumber: '010-1234-1234',
      lastCall: '2024-02-21 13:08',
      status: '완료',
      answer: '타후보'
    },
    {
      key: 6,
      numb: '6',
      callNumber: '010-1234-1234',
      lastCall: '-',
      status: '완료',
      answer: '긍정'
    },
    {
      key: 5,
      numb: '5',
      callNumber: '010-1234-1234',
      lastCall: '2024-02-21 13:08',
      status: '완료',
      answer: '부정'
    },
    {
      key: 4,
      numb: '4',
      callNumber: '010-1234-1234',
      lastCall: '2024-02-21 13:08',
      status: '완료',
      answer: '기타'
    },
    {
      key: 3,
      numb: '3',
      callNumber: '010-1234-1234',
      lastCall: '-',
      status: '완료',
      answer: '긍정'
    },
    {
      key: 2,
      numb: '2',
      callNumber: '010-1234-1234',
      lastCall: '2024-02-21 13:08',
      status: '완료',
      answer: '긍정'
    },
    {
      key: 1,
      numb: '1',
      callNumber: '010-1234-1234',
      lastCall: '2024-02-21 13:08',
      status: '완료',
      answer: '긍정'
    },
  ];

  const detailColumns: TableProps<DetailDataType>['columns'] = [
    {
      title: '전화번호',
      dataIndex: 'callNumber',
      key: 'callNumber',
    },
    {
      title: '마지막 통화',
      dataIndex: 'lastCall',
      key: 'lastCall',
      width: 180,
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text) => text === '완료' ? <Tag color={'blue'} style={{margin: 0}}>{text}</Tag> :
      text === '진행중' ? <Tag color={'orange'} style={{margin: 0}}>{text}</Tag> :
      text ===  '미진행' ? <Tag style={{margin: 0}}>{text}</Tag> : null,
      align: 'center'
    },
  ];

  const detailData: DetailDataType[] = [
    {
      key: 12,
      callNumber: '010-1234-1234',
      lastCall: '-',
      status: '완료',
    },
  ];

  const [detailModal, setDetailModal] = useState(false);
  const detailModalOk = () => {
    setDetailModal(false);
  };
  const detailModalClose = () => {
    setDetailModal(false);
  };

  const [radioValue, setRadioValue] = useState(0);
  const radioChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const [textareaValue, setTextareaValue] = useState('');
  const textChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextareaValue(e.target.value);
  };

  const selectChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const [page, setPage] = useState(1);
  const pageChange: PaginationProps['onChange'] = (pageNumber) => {
    console.log('Page: ', pageNumber);
    setPage(pageNumber);
  };

  const [mobilePushOpen, setMobilePushOpen] = useState(false);
  const openMobilePush = () => {
    setMobilePushOpen(true);
  };

  const navigate = useNavigate();
  const [logoutModal, setLogoutModal] = useState(false);
  const logoutSubmit = () => {
    navigate(routes.default);
  };
  const logoutCancel = () => {
    setLogoutModal(false);
  };

  const [calling, setCalling] = useState(false);
  const startCall = (e: any) => {
    e.stopPropagation();
    setCalling(true);
  };
  const hangUpMobile = () => {
    setCalling(false);
    setMobilePushOpen(true);
  };
  const hangUpDesktop = () => {
    setCalling(false);
    setDetailModal(true);
  };

  return (
    <div className={'split-frame' + (mobilePushOpen || calling ? ' mobile-push' : '')}>
      {/* {
        calling ?
        <CallPush
          callState={'ring'}
          voterData={{}}
          canInfos={[]}
          hangUpMobile={() => hangUpMobile()}
          hangUpDesktop={() => hangUpDesktop()}  /> : null
      } */}
      {
        mobilePushOpen ?
        <MobilePush
          columns={detailColumns}
          data={detailData}
          radioChange={(e:RadioChangeEvent) => radioChange(e)}
          radioValue={radioValue}
          textChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => textChange(e)}
          textareaValue={textareaValue}
          setOpenState={setMobilePushOpen}
        /> : null
      }
      <Modal
        title="로그아웃"
        open={logoutModal}
        onOk={logoutSubmit}
        onCancel={logoutCancel}
        footer={[
          <Button key="back" onClick={logoutCancel}>
            취소
          </Button>,
          <Button key="submit" danger onClick={logoutSubmit}>
            로그아웃
          </Button>
        ]}
      >
        로그아웃 하시겠습니까?
      </Modal>
      <Modal
        title="유권자 정보"
        open={detailModal}
        onOk={detailModalOk}
        onCancel={detailModalClose}
        footer={[
          <Button key="back" onClick={detailModalClose}>
            닫기
          </Button>,
          <Button key="back" onClick={detailModalClose} type='primary'>
            저장
          </Button>,
          <Button key="back" onClick={detailModalClose} type='primary'>
            저장 후 다음 통화
          </Button>
        ]}
      >
        <div className='divide' />
        <div className='modal-cand-voice-body'>
          <Table columns={detailColumns} dataSource={detailData} bordered pagination={false} />
        </div>
        <div className='gap24' />
        <Button type='default' icon={<PhoneOutlined />} size='large' style={{width: '100%'}}>통화하기</Button>
        <div className='gap24' />
        <div className='modal-label'>유권자 성향</div>
        <div className='gap8' />
        <Radio.Group onChange={radioChange} value={radioValue}>
          <Radio value={0}>긍정</Radio>
          <Radio value={1}>부정</Radio>
          <Radio value={2}>타후보에 관심</Radio>
          <Radio value={3}>기타</Radio>
        </Radio.Group>
        <div className='gap8' />
        <div className='modal-label'>메모</div>
        <div className='gap8' />
        <TextArea
          onChange={textChange}
          value={textareaValue}
          placeholder="메모 입력..."
          style={{ height: 120, resize: 'none' }}
        />
        <div className='divide' />
      </Modal>
      <SideNav selected='12' onLogout={onLogout} />
      <div className='split-frame-right-wrap'>
        <div className={'split-frame-right scroll-area' + (mobilePushOpen || calling ? ' mobile-push' : '')}>
          <div className='split-frame-title'>
            유권자 리스트
          </div>
          <div className='mobile-nav mobile-resolution'>
            <div className='mobile-nav-btn' onClick={() => setLogoutModal(true)}><LogoutOutlined style={{color: '#ff7733'}} /></div>
            <div className='mobile-nav-title'>유권자 리스트</div>
            <div className='mobile-nav-btn'></div>
          </div>
          <div className='split-frame-title-divide desktop-resolution' />
          <div className='body-option-row'>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>답변</div>
              <div className='body-option-item'>
                <Select
                  defaultValue="0"
                  style={{ width: 120 }}
                  onChange={selectChange}
                  options={[
                    { value: '0', label: '전체' },
                    { value: '1', label: '긍정' },
                    { value: '2', label: '부정' },
                    { value: '3', label: '타후보' },
                    { value: '3', label: '기타' },
                  ]}
                />
              </div>
            </div>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>전화번호</div>
              <div className='body-option-item'>
                <Input placeholder="전화번호 검색..." style={{width: 160}} />
              </div>
              <div className='body-option-btn-wrap'>
                <Button type="primary" icon={<SearchOutlined />}>검색</Button>
              </div>
            </div>
          </div>
          <div className='mobile-search-btn mobile-resolution'>
            <Button type="primary" icon={<SearchOutlined />} style={{width: '100%'}} size='large' >검색</Button>
          </div>
          <div className='divide desktop-resolution' />
          <div className='table-mobile-wrap'>
            {
              data.map((item: any, idx: number) => {
                if (idx <= page * 10 - 1 && idx >= page * 10 - 10) {
                  return (
                    <div className='voter-list-card' onClick={() => openMobilePush()}>
                      <div className='voter-list-card-left'>
                        <div className='voter-list-card-tags-wrap'>
                          {
                            item.status === '완료' ? <Tag color={'blue'} style={{margin: 0}}>{item.status}</Tag> :
                            item.status === '진행중' ? <Tag color={'orange'} style={{margin: 0}}>{item.status}</Tag> :
                            item.status ===  '미진행' ? <Tag style={{margin: 0}}>{item.status}</Tag> : null
                          }
                          {
                            item.answer === '긍정' ? <Tag color="#30aaff" style={{margin: 0}}>{item.answer}</Tag> :
                            item.answer === '부정' ? <Tag color="#f50" style={{margin: 0}}>{item.answer}</Tag> :
                            item.answer ===  '타후보' ? <Tag color="#30bb60" style={{margin: 0}}>{item.answer}</Tag> :
                            item.answer ===  '기타' ? <Tag color="#888"  style={{margin: 0}}>{item.answer}</Tag> : null
                          }
                        </div>
                        <div className='voter-list-card-call-number'>{item.callNumber}</div>
                      </div>
                      <div className='voter-list-card-call-btn' onClick={(e: any) => startCall(e)}><PhoneTwoTone /></div>
                    </div>
                  );
                }
              })
            }
            <div className='mobile-pagination-wrap'>
              <Pagination defaultCurrent={1} total={data.length} onChange={pageChange} current={page} />
            </div>
          </div>
          <div className='table-desktop-wrap'>
            <Table columns={columns} dataSource={data} bordered />
          </div>
        </div>
      </div>
    </div>
  );
};

const MobilePush:React.FC<MobilePushData> = (props) => {
  return (
    <div className='mobile-push-frame'>
      <div className='mobile-nav'>
        <div className='mobile-nav-btn' onClick={() => props.setOpenState(false)}><CloseOutlined /></div>
        <div className='mobile-nav-title'>유권자 정보</div>
        <div className='mobile-nav-btn'></div>
      </div>
      <div className='mobile-push-frame-body'>
        <div className='modal-cand-voice-body'>
          <Table columns={props.columns} dataSource={props.data} bordered pagination={false} />
        </div>
        <div className='gap24' />
        <Button type='default' icon={<PhoneOutlined />} size='large' style={{width: '100%'}}>통화하기</Button>
        <div className='gap24' />
        <div className='modal-label'>유권자 성향</div>
        <div className='gap8' />
        <Radio.Group onChange={props.radioChange} value={props.radioValue}>
          <Space direction="vertical">
            <Radio value={0}>긍정</Radio>
            <Radio value={1}>부정</Radio>
            <Radio value={2}>타후보에 관심</Radio>
            <Radio value={3}>기타</Radio>
          </Space>
        </Radio.Group>
        <div className='gap8' />
        <div className='modal-label'>메모</div>
        <div className='gap8' />
        <TextArea
          onChange={props.textChange}
          value={props.textareaValue}
          placeholder="메모 입력..."
          style={{ height: 120, resize: 'none' }}
        />
        <div className='gap24' />
        <div className='mobile-push-submit-btns'>
          <Button size='large' style={{flex: '1 0 0'}} onClick={() => props.setOpenState(false)}>취소</Button>
          <Button type='primary' size='large' style={{flex: '1 0 0'}} onClick={() => props.setOpenState(false)}>저장</Button>
        </div>
      </div>
    </div>
  );
};

export default VoterListPage;