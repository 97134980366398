import { PhoneFilled, PlayCircleOutlined } from '@ant-design/icons';
import { Collapse, CollapseProps, Input, Radio, RadioChangeEvent, Table, TableProps, Tag } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';

interface Props{
  hangUpMobile: () => void;
  hangUpDesktop: () => void;
  canInfos: Array<any>;
  voterData: any;
  callState: string;
  radioValue: string;
  setRadioValue: any;
  textareaValue: string;
  setTextareaValue: any;
  callNumber: any;
  lastCall: any;
  status: any;
  key: any;
  selectedUser: any;
}

interface DetailDataType {
  key: number,
  callNumber: string,
  lastCall: string,
  status: string,
}

const CallPush:React.FC<Props> = (props) => {

  const detailColumns: TableProps<DetailDataType>['columns'] = [
    {
      title: '전화번호',
      dataIndex: 'callNumber',
      key: 'callNumber',
    },
    {
      title: '마지막 통화',
      dataIndex: 'lastCall',
      key: 'lastCall',
      width: 180,
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text) => text === '완료' ? <Tag color={'blue'} style={{margin: 0}}>{text}</Tag> :
      text === '진행중' ? <Tag color={'orange'} style={{margin: 0}}>{text}</Tag> :
      text ===  '미진행' ? <Tag style={{margin: 0}}>{text}</Tag> : null,
      align: 'center'
    },
  ];

  const [minutes, setMinutes] = useState<string>('00');
  const [seconds, setSeconds] = useState<string>('00');
  const collapseToggle = (key: string | string[]) => {
    console.log(key);
  };

  useEffect(() => {
    let interval: any;
    if (props.callState === 'calling') {
      interval = setInterval(() => {
        if (parseInt(seconds) === 59) {
          setSeconds('00');
          setMinutes((prevMinutes) => {
            const newMinutes = parseInt(prevMinutes) + 1;
            return newMinutes < 10 ? '0' + newMinutes.toString() : newMinutes.toString();
          });
        } else {
          setSeconds((prevSeconds) => {
            const newSeconds = parseInt(prevSeconds) + 1;
            return newSeconds < 10 ? '0' + newSeconds.toString() : newSeconds.toString();
          });
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [props.callState]);

  return (
    <div className='call-push-bg'>
      <div className='call-push-wrap'>
        <div className='call-push-frame'>
          <div className='call-push-timer'>
            {props.callState === 'ring' ? '전화거는중...' : `${minutes}:${seconds}`}
          </div>
          <div className='call-push-number'>
            {props.voterData.callNumber}
          </div>
          <div className='call-push-hang-up desktop-resolution' onClick={props.hangUpDesktop}>
            <PhoneFilled />
          </div>
          <div className='call-push-hang-up mobile-resolution' onClick={props.hangUpMobile}>
            <PhoneFilled />
          </div>
          <div className='play-voice-btn'><PlayCircleOutlined />녹음 재생</div>
        </div>
        <div className='desktop-resolution flex-gap-12'>
          <div className='call-push-sub'>
            <div className='call-push-sub-wrap scroll-area'>
              <div className='modal-cand-voice-body'>
              <div className='modal-label'>유권자 정보</div>
                <Table columns={detailColumns} dataSource={!props.selectedUser ? [] : [{
                        key: props.key,
                        callNumber: props.callNumber,
                        lastCall: props.lastCall,
                        status: props.status,
                }]} bordered pagination={false} />
              </div>
              <div className='gap24' />
              <div className='modal-label'>유권자 성향</div>
              <div className='gap24' />
              <Radio.Group onChange={(v) => props.setRadioValue(v.target.value)} value={props.radioValue}>
                <Radio value={'긍정'}>긍정</Radio>
                <Radio value={'부정'}>부정</Radio>
                <Radio value={'타후보'}>타후보에 관심</Radio>
                <Radio value={'기타'}>기타</Radio>
              </Radio.Group>
              {
                props.radioValue === '기타' ?
                <>
                  <div className='gap8' />
                  <Input placeholder='기타 답변...' />
                </> : null
              }
              <div className='gap8' />
              <div className='modal-label'>메모</div>
              <div className='gap8' />
              <TextArea
                onChange={(v) => props.setTextareaValue(v.target.value)}
                value={props.textareaValue}
                placeholder="메모 입력..."
                style={{ height: 220, resize: 'none' }}
              />
              <div className='call-push-sub-helper'>입력한 내용은 통화 종료 시 자동으로 저장됩니다.</div>
            </div>
          </div>
          <div className='call-push-sub'>
            <div className='call-push-sub-wrap scroll-area'>
              <div className='modal-label'>후보자 정보</div>
              <div className='gap24' />
              <Collapse items={props.canInfos.map((info: any, i: number) => (  {
                key: i + 1,
                label: info.title,
                children: <p>{info.content}</p>,
              }))} defaultActiveKey={['1']} onChange={collapseToggle} />
            </div>
          </div>
        </div>
        <div className='mobile-resolution'>
          <div className='call-push-sub'>
            <div className='call-push-sub-wrap scroll-area'>
              <div className='modal-cand-voice-body'>
              <div className='modal-label'>유권자 정보</div>
                <Table columns={detailColumns} dataSource={!props.selectedUser ? [] : [{
                        key: props.key,
                        callNumber: props.callNumber,
                        lastCall: props.lastCall,
                        status: props.status,
                }]} bordered pagination={false} />
              </div>
              <div className='gap24' />
              <div className='modal-label'>유권자 성향</div>
              <div className='gap24' />
              <Radio.Group onChange={(v) => props.setRadioValue(v.target.value)} value={props.radioValue}>
                <Radio value={'긍정'}>긍정</Radio>
                <Radio value={'부정'}>부정</Radio>
                <Radio value={'타후보'}>타후보에 관심</Radio>
                <Radio value={'기타'}>기타</Radio>
              </Radio.Group>
              <div className='gap8' />
              <div className='modal-label'>메모</div>
              <div className='gap8' />
              <TextArea
                onChange={(v) => props.setTextareaValue(v.target.value)}
                value={props.textareaValue}
                placeholder="메모 입력..."
                style={{ height: 220, resize: 'none' }}
              />
              <div className='call-push-sub-helper'>입력한 내용은 통화 종료 시 자동으로 저장됩니다.</div>
              <div className='gap24' />
              <div className='modal-label'>후보자 정보</div>
              <div className='gap24' />
              <Collapse items={props.canInfos.map((info: any, i: number) => (  {
                key: i + 1,
                label: info.title,
                children: <p>{info.content}</p>,
              }))} defaultActiveKey={['1']} onChange={collapseToggle} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallPush;