import React, { useState } from 'react';
import SideNav from '../../components/side_nav';
import { Button, DatePicker, Select, Table, TableProps } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

interface DataType {
  key: number,
  serviceName: string,
  date: string,
  totalAmount: string,
  success: string,
  successRatio: string,
  noNumber: string,
  noAnswer: string,
  calling: string,
  cancel: string,
  etc: string,
  connectFail: string,
}

const ResultStatisticPage = (props: any) => {
  const dataSource: DataType[] = [
    {
      key: 1,
      serviceName: '파일명1.xlsx',
      date: '2024-03-06',
      totalAmount: '50,000',
      success: '30,000',
      successRatio: '60%',
      noNumber: '10,000',
      noAnswer: '5,000',
      calling: '3,000',
      cancel: '1,000',
      etc: '500',
      connectFail: '500',
    },
  ];

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '파일명',
      dataIndex: 'serviceName',
      key: 'serviceName',
    },
    {
      title: '일자',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: '송출건수',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: '성공',
      dataIndex: 'success',
      key: 'success',
    },
    {
      title: '성공률',
      dataIndex: 'successRatio',
      key: 'successRatio',
    },
    {
      title: '결번',
      dataIndex: 'noNumber',
      key: 'noNumber',
    },
    {
      title: '무응답',
      dataIndex: 'noAnswer',
      key: 'noAnswer',
    },
    {
      title: '통화중',
      dataIndex: 'calling',
      key: 'calling',
    },
    {
      title: '취소',
      dataIndex: 'cancel',
      key: 'cancel',
    },
    {
      title: '기타',
      dataIndex: 'etc',
      key: 'etc',
    },
    {
      title: '연결실패',
      dataIndex: 'connectFail',
      key: 'connectFail',
    },
  ];

  const {onLogout} = props;

  const [folders, setFolders] = useState<any>([{
      title: '파일1.xlsx',
      _id: 0,
    },{
      title: '파일2.xlsx',
      _id: 1,
    },{
      title: '파일3.xlsx',
      _id: 2,
    },{
      title: '파일4.xlsx',
      _id: 3,
    }]);
    const [selectedFolder, setSelectedFolder] = useState('파일1.xlsx');
    const selectChange = (value: string) => {
      setSelectedFolder(value);
    };

  return (
    <div className='split-frame'>
      <SideNav selected='9' onLogout={onLogout} />
      <div className='split-frame-right-wrap'>
        <div className='split-frame-right scroll-area'>
          <div className='split-frame-title'>
            결과 분석
          </div>
          <div className='split-frame-title-divide' />
          <div className='body-option-row'>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>파일명</div>
              <div className='body-option-item'>
                <Select
                  style={{ width: 240 }}
                  onChange={selectChange}
                  value={selectedFolder}
                  options={
                    folders.map((folder: any) => {
                      return {
                        label: folder.title,
                        value: folder._id,
                      };
                    })
                  }
                />
              </div>
            </div>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>조회기간</div>
              <div className='body-option-item'>
                <DatePicker onChange={() => console.log('sdf')} placeholder='시작일' /> ~ 
                <DatePicker onChange={() => console.log('sdf')} placeholder='종료일'/>
              </div>
              <div className='body-option-btn-wrap'>
                <Button type="primary" icon={<SearchOutlined />} onClick={() => console.log('sdf')}>검색</Button>
              </div>
            </div>
          </div>
          <div className='divide' />
          <div className='gap8' />
          <Table columns={columns} dataSource={dataSource} bordered pagination={false} />
        </div>
      </div>
    </div>
  );
};

export default ResultStatisticPage;