import React from 'react';
import SideNav from '../../components/side_nav';
import { Table, TableProps } from 'antd';

interface DataType {
  key: number,
  billingAmount: string,
  usedTime: string,
  usedAmount: string,
  remainAmount: string,
}

const ChargeStatisticPage = (props: any) => {
  const dataSource: DataType[] = [
    {
      key: 1,
      billingAmount: '-',
      usedTime: '00:00:00',
      usedAmount: '-',
      remainAmount: '-',
    },
  ];

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '결제금액(원)',
      dataIndex: 'billingAmount',
      key: 'billingAmount',
    },
    {
      title: '사용시간',
      dataIndex: 'usedTime',
      key: 'usedTime',
    },
    {
      title: '사용금액(원)',
      dataIndex: 'usedAmount',
      key: 'usedAmount',
    },
    {
      title: '남은금액(원)',
      dataIndex: 'remainAmount',
      key: 'remainAmount',
    },
  ];

  const {onLogout} = props;
  return (
    <div className='split-frame'>
      <SideNav selected='9' onLogout={onLogout} />
      <div className='split-frame-right-wrap'>
        <div className='split-frame-right scroll-area'>
          <div className='split-frame-title'>
            요금 현황
          </div>
          <Table columns={columns} dataSource={dataSource} bordered pagination={false} />
        </div>
      </div>
    </div>
  );
};

export default ChargeStatisticPage;