import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import SideNav from '../../components/side_nav';
import { Button, Input, Modal, Pagination, PaginationProps, Radio, RadioChangeEvent, Select, Space, Table, TableProps, Tag } from 'antd';
import { CloseOutlined, LogoutOutlined, PhoneOutlined, PhoneTwoTone, SearchOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';
import routes from '../../config/routes';
import CallPush from '../../components/call';
import api from '../../config/api';

import JsSIP from 'jssip';
import { jwtDecode } from 'jwt-decode';

const socket = new JsSIP.WebSocketInterface('wss://aiesc.touchcall.io:15568/ws');
const getSipConfigs = (uri: string, password: string) => ({
  sockets: [socket],
  uri,
  password,
});


interface DataType {
  key: number,
  numb: string,
  callNumber: string,
  lastCall: string,
  status: string,
  answer: string,
}

interface DetailDataType {
  key: number,
  callNumber: string,
  lastCall: string,
  status: string,
}

interface MobilePushData {
  columns: any[];
  data: any[];
  radioChange: (e:RadioChangeEvent) => void;
  radioValue: string;
  textChange: (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  textareaValue: string;
  setOpenState: Dispatch<SetStateAction<boolean>>;
  callState: string;
}

const getpn = (str: string) => {
  if (str.length === 10 && str[0] === '1' && str[1] === '0') {
    return `0${str}`;
  }

  return str;
};

const VoterListPage = (props: any) => {
  const {onLogout, phoneNumber} = props;
  const ua = useRef<any>();

  useEffect(() => {
    const d: any = jwtDecode(localStorage.getItem('access_token') ?? '');
    ua.current = new JsSIP.UA(getSipConfigs(`${d.sip1}@aiesc.touchcall.io`, d.sip2));

    ua.current.start();
    ua.current.register();

    return () => {
      ua.current.unregister();
    };
  }, []);

  const call = (number: string) => {
    setDetailModal(false);
    setCalling(true);
    setCallState('ring');

    const eventHandlers = {
      'progress': function() {
        console.log('call is in progress');
        setCallState('ring');
      },
      'failed': function(e: any) {
        console.log(e);
        console.log('call failed with cause: ');
        console.log(calling);
        console.log(callState);
        setCallState('end');
      },
      'ended': function(e: any) {
        console.log(e);
        console.log('call ended with cause: ');
        setCallState('end');
      },
      'confirmed': function(e: any) {
        console.log('call confirmed');
        setCallState('calling');
      }
    };

    const options = {
      'eventHandlers'    : eventHandlers,
      'mediaConstraints' : { 'audio': true, 'video': false },
      'extraHeaders': [`X-TCall-CallerNumber: ${phoneNumber}`],
    };

    const session = ua.current.call(number, options);

    if (session) {
      session.connection.addEventListener('addstream', (e: any) => {
        var audio = document.createElement('audio');
        audio.srcObject = e.stream;
        audio.play();  
      });
    }  
  }

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '번호',
      dataIndex: 'numb',
      key: 'numb',
      width: 100,
      align: 'center',
    },
    {
      title: '전화번호',
      dataIndex: 'callNumber',
      key: 'callNumber',
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (text, r) => <a onClick={() => {
        setSelectedUser(r);
        setDetailModal(true);
      }}>{text}</a>
    },
    {
      title: '마지막 통화',
      dataIndex: 'lastCall',
      key: 'lastCall',
      width: 180,
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text) => text === '완료' ? <Tag color={'blue'} style={{margin: 0}}>{text}</Tag> :
      text === '진행중' ? <Tag color={'orange'} style={{margin: 0}}>{text}</Tag> :
      text ===  '미진행' ? <Tag style={{margin: 0}}>{text}</Tag> : null,
      align: 'center'
    },
    {
      title: '답변',
      dataIndex: 'answer',
      key: 'answer',
      width: 100,
      render: (text) => text === '긍정' ? <Tag color="#30aaff" style={{margin: 0}}>{text}</Tag> :
      text === '부정' ? <Tag color="#f50" style={{margin: 0}}>{text}</Tag> :
      text ===  '타후보' ? <Tag color="#30bb60" style={{margin: 0}}>{text}</Tag> :
      text ===  '기타' ? <Tag color="#888"  style={{margin: 0}}>{text}</Tag> : null,
      align: 'center'
    },
    // {
    //   title: '통화',
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: 72,
    //   render: (text, record, index) =>
    //   text === '미진행' ? <div className='call-btn-wrap' onClick={() => {
    //     setSelectedUser(record);
    //     call(getpn(record.callNumber));
    //   }}><PhoneTwoTone /></div> :
    //   text === '진행중' ? <div className='call-btn-wrap' onClick={() => {
    //     setSelectedUser(record);
    //     call(getpn(record.callNumber));
    //   }}><PhoneTwoTone /></div> :
    //   text === '완료' ? <div className='call-btn-wrap' onClick={() => {
    //     setSelectedUser(record);
    //     call(getpn(record.callNumber));
    //   }}><PhoneTwoTone /></div> : '-',
    //   align: 'center'
    // },
  ];

  const detailColumns: TableProps<DetailDataType>['columns'] = [
    {
      title: '전화번호',
      dataIndex: 'callNumber',
      key: 'callNumber',
    },
    {
      title: '마지막 통화',
      dataIndex: 'lastCall',
      key: 'lastCall',
      width: 180,
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (text) => text === '완료' ? <Tag color={'blue'} style={{margin: 0}}>{text}</Tag> :
      text === '진행중' ? <Tag color={'orange'} style={{margin: 0}}>{text}</Tag> :
      text ===  '미진행' ? <Tag style={{margin: 0}}>{text}</Tag> : null,
      align: 'center'
    },
  ];

  const [detailModal, setDetailModal] = useState(false);
  const detailModalOk = () => {
    setDetailModal(false);
  };
  const detailModalClose = () => {
    setDetailModal(false);
  };

  const [radioValue, setRadioValue] = useState('긍정');
  const radioChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  const [textareaValue, setTextareaValue] = useState('');
  const textChange = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextareaValue(e.target.value);
  };

  const selectChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const pageChange: PaginationProps['onChange'] = (pageNumber) => {
    console.log('Page: ', pageNumber);
    setPage(pageNumber);
  };

  const [mobilePushOpen, setMobilePushOpen] = useState(false);
  const openMobilePush = () => {
    setMobilePushOpen(true);
  };

  const navigate = useNavigate();
  const [logoutModal, setLogoutModal] = useState(false);
  const logoutSubmit = () => {
    navigate(routes.default);
  };
  const logoutCancel = () => {
    setLogoutModal(false);
  };

  const [calling, setCalling] = useState(false);
  const startCall = (e: any) => {
    e.stopPropagation();
    setCalling(true);
  };
  const hangUpMobile = () => {
    setCalling(false);
    setMobilePushOpen(true);
    ua.current.terminateSessions();
  };
  const hangUpDesktop = () => {
    setCalling(false);
    setDetailModal(true);
    ua.current.terminateSessions();
  };

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('전체');
  const [answer, setAnswer] = useState('전체');
  const [keyword, setKeyword] = useState('');
  const [totals, setTotals] = useState(0);

  const [selectedUser, setSelectedUser] = useState<any>(null);

  useEffect(() => {
    if (selectedUser) {
      setTextareaValue(selectedUser.answer_memo);
      setRadioValue(selectedUser.answer);
    }
  }, [selectedUser]);

  useEffect(() => {
    req();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (status && answer) {
      setPage(1);
      setTimeout(() => {
        req();
      }, 300);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, answer]);

  const [canInfos, setCanInfos] = useState([]);
  const canInfoReq = async () => {
    const res = await fetch(`${api.get_candidate_info}`, {
      'method': 'GET',
      'headers': {
        'access-token': localStorage.getItem('access_token') ?? '',
      },
    });

    const json = await res.json();
    setCanInfos(json.data.infos);
  };

  const req = async () => {
    const res = await fetch(`${api.get_surveyor_record}?keyword=${keyword}&page=${page}&status=${status}&answer=${answer}`, {
      'method': 'GET',
      'headers': {
        'access-token': localStorage.getItem('access_token') ?? '',
      },
    });

    const json = await res.json();

    setTotals(json.data.totals);
    setData(json.data.users.map((user: any, i: number) => ({
      key: user._id,
      numb: (json.data.totals - ((page - 1) * 20) - i).toString(),
      callNumber: user.phone_number,
      lastCall:  !user.last_call_time ? '-' : new Date(user.last_call_time).toLocaleTimeString(),
      status: user.status === 'yet' ? '미진행' : user.status === 'calling' ? '진행중' : '완료',
      answer: !user.answer_choice ? '-' : user.answer_choice,
      answer_memo: user.answer_memo,
      records: user.record_id,
    })));
  };

  const search = async () => {
    setPage(1);
    setTimeout(() => {
      req();
    }, 300);
  };


  useEffect(() => {
    canInfoReq();
  }, []);

  const [callState, setCallState] = useState('ended');
  const [callingData, setCallingData] = useState(null);
  const [callStartTime, setCallStartTime] = useState<any>();

  useEffect(() => {
    if (callState === 'end') {
      setCalling(false);

      if (callingData) {
        console.log(callingData);
        createCallRecord();
        return;
      }

      setCallingData(null);
      a();
    } else if (callState === 'calling') {
      setCallStartTime(new Date().getTime());
      setCallingData(selectedUser);
    }
  }, [callState]);

  const a = async () => {
    await req();
    const uid = selectedUser.key;
    const d = data.filter((v: any) => v.key === uid);
    setSelectedUser(d[0]);

    setDetailModal(true);
  }

  const createCallRecord = async () => {
    await fetch(`${api.create_record}`, {
      'method': 'POST',
      'headers': {
        'access-token': localStorage.getItem('access_token') ?? '',
        'Content-Type': 'application/json',
      },
      'body': JSON.stringify({
        voterId: selectedUser.key,
        answerChoice: radioValue,
        answerMemo: textareaValue,
        lastCallTime: callStartTime,
        lastCallEndTime: new Date().getTime(),
        status: 'completed',
      }),
    });

    setCallingData(null);
    a();
  };

  const updateSurvey = async () => {
    await fetch(`${api.update_survey}`, {
      'method': 'POST',
      'headers': {
        'access-token': localStorage.getItem('access_token') ?? '',
        'Content-Type': 'application/json',
      },
      'body': JSON.stringify({
        voterId: selectedUser.key,
        answerChoice: radioValue,
        answerMemo: textareaValue,
      }),
    });

    setTextareaValue('');
    setRadioValue('긍정');

    req();
  };

  return (
    <div className={'split-frame' + (mobilePushOpen || calling ? ' mobile-push' : '')}>
      {
        calling ?
        <CallPush
          callState={callState}
          hangUpMobile={() => hangUpMobile()}
          hangUpDesktop={() => hangUpDesktop()}
          canInfos={canInfos}
          voterData={selectedUser}
          radioValue={radioValue}
          setRadioValue={setRadioValue}
          textareaValue={textareaValue}
          setTextareaValue={setTextareaValue}
          callNumber={selectedUser.callNumber}
          lastCall={selectedUser.lastCall}
          status={selectedUser.status}
          key={selectedUser.key}
          selectedUser={selectedUser}
        /> : null
      }
      {
        mobilePushOpen ?
        <MobilePush
          callState={callState}
          columns={detailColumns}
          data={!selectedUser ? [] : [{
            key: selectedUser.key,
            callNumber: selectedUser.callNumber,
            lastCall: selectedUser.lastCall,
            status: selectedUser.status,
          }]}
          radioChange={(e:RadioChangeEvent) => radioChange(e)}
          radioValue={radioValue}
          textChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => textChange(e)}
          textareaValue={textareaValue}
          setOpenState={setMobilePushOpen}
        /> : null
      }
      <Modal
        title="로그아웃"
        open={logoutModal}
        onOk={logoutSubmit}
        onCancel={logoutCancel}
        footer={[
          <Button key="back" onClick={logoutCancel}>
            취소
          </Button>,
          <Button key="submit" danger onClick={logoutSubmit}>
            로그아웃
          </Button>
        ]}
      >
        로그아웃 하시겠습니까?
      </Modal>
      <Modal
        title="유권자 정보"
        open={detailModal}
        onOk={detailModalOk}
        onCancel={detailModalClose}
        footer={[
          <Button key="back" onClick={detailModalClose}>
            닫기
          </Button>,
          <Button key="back" onClick={() => {
            updateSurvey();
            detailModalClose();
          }} type='primary'>
            저장
          </Button>,
          <Button key="back" onClick={detailModalClose} type='primary'>
            저장 후 다음 통화
          </Button>
        ]}
      >
        <div className='divide' />
        <div className='modal-cand-voice-body'>
          <Table columns={detailColumns} dataSource={!selectedUser ? [] : [{
                  key: selectedUser.key,
                  callNumber: selectedUser.callNumber,
                  lastCall: selectedUser.lastCall,
                  status: selectedUser.status,
          }]} bordered pagination={false} />
        </div>
        <div className='gap24' />
        <Button type='default' icon={<PhoneOutlined />} size='large' style={{width: '100%'}} onClick={() => call(getpn(selectedUser.callNumber))}>통화하기</Button>
        <div className='gap24' />
        <div className='modal-label'>유권자 성향</div>
        <div className='gap8' />
        <Radio.Group onChange={radioChange} value={radioValue}>
          <Radio value={'긍정'}>긍정</Radio>
          <Radio value={'부정'}>부정</Radio>
          <Radio value={'타후보'}>타후보에 관심</Radio>
          <Radio value={'기타'}>기타</Radio>
        </Radio.Group>
        {
          radioValue === '기타' ?
          <>
            <div className='gap8' />
            <Input placeholder='기타 답변...' />
          </> : null
        }
        <div className='gap8' />
        <div className='modal-label'>메모</div>
        <div className='gap8' />
        <TextArea
          onChange={textChange}
          value={textareaValue}
          placeholder="메모 입력..."
          style={{ height: 120, resize: 'none' }}
        />
        <div className='divide' />
      </Modal>
      <SideNav selected='11' onLogout={onLogout} />
      <div className='split-frame-right-wrap'>
        <div className={'split-frame-right scroll-area' + (mobilePushOpen || calling ? ' mobile-push' : '')}>
          <div className='split-frame-title'>
            유권자 리스트
          </div>
          <div className='mobile-nav mobile-resolution'>
            <div className='mobile-nav-btn' onClick={() => setLogoutModal(true)}><LogoutOutlined style={{color: '#ff7733'}} /></div>
            <div className='mobile-nav-title'>유권자 리스트</div>
            <div className='mobile-nav-btn'></div>
          </div>
          <div className='split-frame-title-divide desktop-resolution' />
          <div className='body-option-row'>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>상태</div>
              <div className='body-option-item'>
                <Select
                  value={status}
                  style={{ width: 120 }}
                  onChange={setStatus}
                  options={[
                    { value: '전체', label: '전체' },
                    { value: '미진행', label: '미진행' },
                    { value: '진행중', label: '진행중' },
                    { value: '완료', label: '완료' },
                  ]}
                />
              </div>
            </div>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>답변</div>
              <div className='body-option-item'>
                <Select
                  value={answer}
                  style={{ width: 120 }}
                  onChange={setAnswer}
                  options={[
                    { value: '전체', label: '전체' },
                    { value: '긍정', label: '긍정' },
                    { value: '부정', label: '부정' },
                    { value: '타후보', label: '타후보' },
                    { value: '기타', label: '기타' },
                  ]}
                />
              </div>
            </div>
            <div className='body-option-row-inner'>
              <div className='body-option-label'>전화번호</div>
              <div className='body-option-item'>
                <Input placeholder="전화번호 검색..." style={{width: 160}} value={keyword} onChange={({target: {value}}) => setKeyword(value)} />
              </div>
              <div className='body-option-btn-wrap'>
                <Button type="primary" icon={<SearchOutlined />} onClick={search}>검색</Button>
              </div>
            </div>
          </div>
          <div className='mobile-search-btn mobile-resolution'>
            <Button type="primary" icon={<SearchOutlined />} style={{width: '100%'}} size='large' onClick={search}>검색</Button>
          </div>
          <div className='divide desktop-resolution' />
          <div className='table-mobile-wrap'>
            {
              data.map((item: any, idx: number) => {
                if (idx <= page * 10 - 1 && idx >= page * 10 - 10) {
                  return (
                    <div className='voter-list-card' onClick={() => openMobilePush()}>
                      <div className='voter-list-card-left'>
                        <div className='voter-list-card-tags-wrap'>
                          {
                            item.status === '완료' ? <Tag color={'blue'} style={{margin: 0}}>{item.status}</Tag> :
                            item.status === '진행중' ? <Tag color={'orange'} style={{margin: 0}}>{item.status}</Tag> :
                            item.status ===  '미진행' ? <Tag style={{margin: 0}}>{item.status}</Tag> : null
                          }
                          {
                            item.answer === '긍정' ? <Tag color="#30aaff" style={{margin: 0}}>{item.answer}</Tag> :
                            item.answer === '부정' ? <Tag color="#f50" style={{margin: 0}}>{item.answer}</Tag> :
                            item.answer ===  '타후보' ? <Tag color="#30bb60" style={{margin: 0}}>{item.answer}</Tag> :
                            item.answer ===  '기타' ? <Tag color="#888"  style={{margin: 0}}>{item.answer}</Tag> : null
                          }
                        </div>
                        <div className='voter-list-card-call-number'>{item.callNumber}</div>
                      </div>
                      <div className='voter-list-card-call-btn' onClick={(e: any) => startCall(e)}><PhoneTwoTone /></div>
                    </div>
                  );
                }
              })
            }
            <div className='mobile-pagination-wrap'>
              <Pagination defaultCurrent={1} total={totals} onChange={pageChange} current={page} />
            </div>
          </div>
          <div className='table-desktop-wrap'>
            <Table columns={columns} dataSource={data} bordered />
          </div>
        </div>
      </div>
    </div>
  );
};

const MobilePush:React.FC<MobilePushData> = (props) => {
  return (
    <div className='mobile-push-frame'>
      <div className='mobile-nav'>
        <div className='mobile-nav-btn' onClick={() => props.setOpenState(false)}><CloseOutlined /></div>
        <div className='mobile-nav-title'>유권자 정보</div>
        <div className='mobile-nav-btn'></div>
      </div>
      <div className='mobile-push-frame-body'>
        <div className='modal-cand-voice-body'>
          <Table columns={props.columns} dataSource={props.data} bordered pagination={false} />
        </div>
        <div className='gap24' />
        <Button type='default' icon={<PhoneOutlined />} size='large' style={{width: '100%'}}>통화하기</Button>
        <div className='gap24' />
        <div className='modal-label'>유권자 성향</div>
        <div className='gap8' />
        <Radio.Group onChange={props.radioChange} value={props.radioValue}>
          <Space direction="vertical">
            <Radio value={0}>긍정</Radio>
            <Radio value={1}>부정</Radio>
            <Radio value={2}>타후보에 관심</Radio>
            <Radio value={3}>기타</Radio>
          </Space>
        </Radio.Group>
        <div className='gap8' />
        <div className='modal-label'>메모</div>
        <div className='gap8' />
        <TextArea
          onChange={props.textChange}
          value={props.textareaValue}
          placeholder="메모 입력..."
          style={{ height: 120, resize: 'none' }}
        />
        <div className='gap24' />
        <div className='mobile-push-submit-btns'>
          <Button size='large' style={{flex: '1 0 0'}} onClick={() => props.setOpenState(false)}>취소</Button>
          <Button type='primary' size='large' style={{flex: '1 0 0'}} onClick={() => props.setOpenState(false)}>저장</Button>
        </div>
      </div>
    </div>
  );
};

export default VoterListPage;