const routes = {
  'default': '/',
  'voterRegister': '/voter_register',
  'assign': '/assign',
  'candInfo': '/cand_info',
  'candVoice': '/cand_voice',
  'aiVoice': '/ai_voice',
  'researchList': '/research_list',
  'taskStatistic': '/task_statistic',
  'account': '/account',
  'voterList': '/voter_list',
  'cdVoterList': '/cd_voter_list',
  'download': '/download',
  'answerSetting': '/answer_setting',
  'chargeStatistic': '/charge_statistic',
  'resultStatistic': '/result_statistic',
};

export default routes;